<div class="row">
  <div class="col-8 col-md-9" >
    <br />
    <h5 *ngIf="numberData > 0" class="font-weight-300">Últimos {{numberData}} clientes de tu plan
    </h5>
  </div>
</div>
  <div class="row">
    <div class="col-12 col-md-12" >
      <div class="card overflow-hidden px-4">
        <div class="card-body card-bodyS px-0" style="overflow: scroll;">
          <ng2-smart-table   
          *ngIf="carga_info"
          [settings]="settings" 
          [source]="source" 
          (custom)="onCustom($event)"
          >
        </ng2-smart-table>
        <div *ngIf="loading" class="loading-overlay">
          <div class="spinner"></div>
          <span>Cargando...</span>
        </div>
        </div>
      </div>
    </div>
  </div>