import { Component, DoCheck, OnInit } from '@angular/core';
import { faCircle, faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { PedidoResumen } from 'src/app/models/pedido_resumen.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PedidosService } from 'src/app/services/pedidos/pedidos.service';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';

@Component({
  selector: 'app-pedidos-en-curso',
  templateUrl: './pedidos-en-curso.component.html',
  styleUrls: ['./pedidos-en-curso.component.css'],
})
export class PedidosEnCursoComponent implements OnInit, DoCheck {
  puntoSeleccionado:any = 'todos';
  pedidosGenerales: any;
  copyPedidosGenerales: any;
  copy_pedidos_por_aprobar: any;
  copy_pedidosPendientes: any;
  copy_pedidosAprobadosInterno: any;
  copy_pedidosAprobadosExterno: any;
  copy_pedidosAlistamientos: any;
  copy_pedidosDespachados: any;
  copy_pedidosFacturados: any;
  copy_pedidos_sugeridos: any;
  // Evita que al hacer click por fuera se cierre el modal
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'md',
  };
  pedidos_sugeridos_view= false;
  punto_entrega: any;
  constructor(
    private rest: RestService,
    public auth: AuthService,
    private router: Router,
    private pedidosService: PedidosService,
    private modalService: NgbModal,
    public localStorage: LocalStorageService
  ) {}

  /** Variable de control para la navegación interna del panel */
  active = 1;
  puntosHo: any = [];
  /** Referencias a íconos FontAwesome para la UI */
  faSearch = faSearch;
  faCircle = faCircle;
  faTimesCircle = faTimesCircle;

  /** Imagen distribuidor placeholder */
  distribuidor_placeholder = '../../assets/img/icon-organizacion.png';

  /** Lista de meses para apoyar el formateo de la fecha */
  private meses: string[] = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  /** Listas de pedidos para resumen */
  public pedidos: PedidoResumen[] = [];
  public listaPuntos: PedidoResumen[] = [];
  public pedidos_filtrados: PedidoResumen[] = [];
  public pedidos_sugeridos: PedidoResumen[] = [];
  public pedidos_por_aprobar: PedidoResumen[] = [];

  /** Para el filtrado por nombre de distribuidor */
  public nombre_dist = '';
  existePedidoCurso = false;
  pedidosPendientes: any = [];
  pedidosAprobadosInterno: any = [];
  pedidosAprobadosExterno: any = [];
  pedidosAlistamientos: any = [];
  pedidosDespachados: any = [];
  pedidosFacturados: any = [];
  pedidosCalificados: any = [];

  /**Placeholder imagenes */
  product_placeholder = '../../assets/img/product-placeholder.png';

  async ngOnInit(): Promise<void> {
    //await this.validarDistribuidorVinculado();
    await this.consultarPuntos();
    await this.consultarPedidos();
    await this.autoCompletadoBusqueda();
   

  }

  ngDoCheck(): void {
    if (this.nombre_dist == '') {
      this.pedidos_filtrados = this.pedidos;
    } else {
      this.pedidos_filtrados = [];
      for (const aux of this.pedidos) {
        if (
          this.toUnaccentedLowerCase(aux.nombre_distribuidor).includes(this.toUnaccentedLowerCase(this.nombre_dist))
        ) {
          this.pedidos_filtrados.push(aux);
        }
      }
    }
  }

  /**
   * Recibe cualquier string y devuelve el mismo string, pero
   * en minúsulcas y sin tildes ni diéresis
   * @param str El string a convertir
   * @returns El string convertido
   */
  private toUnaccentedLowerCase(str: string): string {
    return str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }
  public consultarPuntos() {
    if (this.auth.user_horeca?._id) {
      this.pedidosService
        .getPuntosEstablecimientosHo(this.auth.user_horeca?._id)
        .toPromise()
        .then((data: any) => {
          if (this.auth.user?.tipo_trabajador === 'PROPIETARIO/REP LEGAL') {
            this.pedidos_sugeridos_view = true;
            for (const punto of data) {
              this.listaPuntos.push(punto);
            }
          } else {
            for (const punto of data) {
              this.puntosHo = this.auth.user?.puntos_entrega;
              if (this.auth.user?.puntos_entrega && this.puntosHo.length > 0) {
                // eslint-disable-next-line no-unsafe-optional-chaining
                for (const puntoU of this.puntosHo) {
                  let idPuntoUser;
                  if (puntoU['_id']) {
                    idPuntoUser = puntoU['_id'];
                  } else {
                    idPuntoUser = puntoU;
                  }
                  if (idPuntoUser === punto._id) {
                    this.listaPuntos.push(punto);
                  }
                }
              }
            }
          }
        })
        .catch((err: any) => {});
    }
  }
  filtrarPedidos() {
    this.pedidos_por_aprobar = [];
    this.pedidosPendientes = [];
    this.pedidosAprobadosInterno = [];
    this.pedidosAprobadosExterno = [];
    this.pedidosAlistamientos = [];
    this.pedidosDespachados = [];
    this.pedidosFacturados = [];
    this.pedidos_sugeridos = [];
    this.pedidos = [];
    if (this.puntoSeleccionado == 'todos') {
      if (this.auth.user?.tipo_trabajador === 'PROPIETARIO/REP LEGAL') {
        for (const producto of this.copyPedidosGenerales) {
          if (producto.usuario_horeca == this.auth.user_horeca?._id) {
            this.agregarPedido(producto);
          }
        }
      } else {
       
        for (const producto of this.copyPedidosGenerales) {
          this.puntosHo = this.auth.user?.puntos_entrega;
          if (this.auth.user?.puntos_entrega && this.puntosHo.length > 0) {
            // eslint-disable-next-line no-unsafe-optional-chaining
            for (const punto of this.puntosHo) {
              let idPuntoUser;
              if (punto['_id']) {
                idPuntoUser = punto['_id'];
              } else {
                idPuntoUser = punto;
              }
              if (idPuntoUser === producto.punto_entrega) {
                this.agregarPedido(producto);
              }
            }
          }
        }
      }
    } else {
      const index = this.listaPuntos.findIndex((catSel: { _id: any; }) => catSel._id === this.puntoSeleccionado);
      if (index !== -1) {
        this.localStorage.setItem<any>('punto_entrega_seleccionado',  this.listaPuntos[index]);
      }
      console.log('this.pedidosGenerales', this.pedidosGenerales)
      for (const producto of this.pedidosGenerales) {
        if (this.puntoSeleccionado === producto.punto_entrega) {
          this.agregarPedido(producto);
        }
      }
    }
  }
  goSugerido(id: any) {
    if (!this.existePedidoCurso) {
      window.location.href = '/pedidos/sugeridos/' + id;
    } else {
      const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = `Ya existe un pedido sugerido cargado en tu carrito`;
      modalRef.componentInstance.btn_msg = 'Volver';
    }
  }
  /**
   * Recupera la lista de todos los pedidos y agrega los que
   * pertenezcan a este usuario horeca
   */
  private consultarPedidos() {
    if (this.auth.user_horeca?._id) {
      this.pedidosService
        .getPedidoPorEstablecimiento(this.auth.user_horeca?._id)
        .toPromise()
        .then((productos: any) => {

          this.pedidosGenerales = productos;
          this.copyPedidosGenerales = productos;
          if (this.auth.user?.tipo_trabajador === 'PROPIETARIO/REP LEGAL') {
            for (const producto of productos) {
              if (producto.usuario_horeca == this.auth.user_horeca?._id) {
                this.agregarPedido(producto);
              }
            }
          } else {

            for (const producto of productos) {
              this.puntosHo = this.auth.user?.puntos_entrega;
              if (this.auth.user?.puntos_entrega && this.puntosHo.length > 0) {
                // eslint-disable-next-line no-unsafe-optional-chaining
                for (const punto of this.puntosHo) {
                  let idPuntoUser;
                  if (punto['_id']) {
                    idPuntoUser = punto['_id'];
                  } else {
                    idPuntoUser = punto;
                  }
                  if (idPuntoUser === producto.punto_entrega) {
                    this.agregarPedido(producto);
                  }
                }
              }
            }
          }
          /** Si es un trabjador planeador de pedidos solo muestra sus pedidos
          if (this.auth.user?.tipo_trabajador === 'PLANEADOR PEDIDOS') {
            console.log('ing Aqui 0');
            for (const producto of productos) {
              if (
                producto.usuario_horeca === this.auth.user_horeca?._id &&
                this.auth.user._id === producto.trabajador
              ) {
                this.agregarPedido(producto);
              }
            }
          } else {
            console.log('ing Aqui 2');
            for (const producto of productos) {
              if (producto.usuario_horeca == this.auth.user_horeca?._id) {
                this.agregarPedido(producto);
              }
            }
          } */
            this.punto_entrega = JSON.parse(localStorage.getItem('punto_entrega_seleccionado')!);
            if(this.punto_entrega){
              this.puntoSeleccionado  = this.punto_entrega._id;
            }
            console.log('punto_entrega', this.punto_entrega)
            this.filtrarPedidos();
        })
        .catch((err: any) => {
          const modalRef = this.modalService.open(SimpleComponent, {
            centered: true,
            size: 'md',
          });
          modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
          modalRef.componentInstance.title = '¡Oh oh!';
          modalRef.componentInstance.msg = 'No fue posible recuperar los pedidos, intenta de nuevo mas tarde.';
          modalRef.componentInstance.btn_msg = 'Listo';
          modalRef.componentInstance.close_callback = () => {
            this.router.navigate(['/inicio']);
          };
        });
    }
  }

  /**
   * Procesa los datos del pedido crudo y agregar el pedido a la lista de
   * pedidos a mostrar. El procesamiento incluye consultar el nombre y
   * el logo del distribuidor encargado del pedido
   * @param pedido Los datos crudos del pedido a agregar
   */
  private agregarPedido(pedido: any) {
    const ped: PedidoResumen = new PedidoResumen(
      pedido.estado || '',
      pedido.data_distribuidor[0].nombre  || '',
      this.procesarFecha(pedido.fecha),
      pedido.productos.length || 0,
      this.procesarUnidades(pedido.productos),
      pedido.data_distribuidor[0].logo || this.product_placeholder,
      pedido._id  || '',
      pedido.total_pedido  || '',
      pedido.id_pedido  || ''
    );
    this.pedidos.push(ped);
    /** Dependiendo el estado guarda los productos */
    if (ped.estado == 'Pendiente') {
      this.pedidos_por_aprobar.push(ped);
      this.pedidosPendientes.push(ped);
    } else if (ped.estado == 'Aprobado Interno') {
      this.pedidosAprobadosInterno.push(ped);
    } else if (ped.estado == 'Aprobado Externo') {
      this.pedidosAprobadosExterno.push(ped);
    } else if (ped.estado == 'Alistamiento') {
      this.pedidosAlistamientos.push(ped);
    } else if (ped.estado == 'Despachado') {
      this.pedidosDespachados.push(ped);
    } else if (ped.estado == 'Facturado') {
      this.pedidosFacturados.push(ped);
    } else if (ped.estado == 'Sugerido') {
      this.pedidos_sugeridos.push(ped);
    }
  }

  /**
   * Toma el formato de fecha de Date y devuelve un string de la forma "Mes día"
   * (e.g. Noviembre 4)
   * @param fecha_cruda La fecha en formato <año>-<mes>-<día>T<hora>
   * @returns La fecha en formato <Mes> <día>
   */
  private procesarFecha(fecha_cruda: string): string {
    const date = new Date(fecha_cruda);
    const ans = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
    return this.meses[ans.month - 1] + ' ' + ans.day;
  }

  /**
   * Recibe un arreglo con la información de los productos del pedido
   * y cuenta las unidades totales del pedido
   * @param productos El arreglo con la información de los productos
   * @returns El número de unidades del pedido
   */
  private procesarUnidades(productos: any): number {
    let ans = 0;

    for (const aux of productos) {
      ans += aux.unidad;
    }

    return ans;
  }

  /**Guardamos dinamicamente los valores de autocompletado */
  filteredOptions: any;
  mySearch = new FormControl();

  /*Este metodo tiene como objeto autocompletar la busqueda del usuario */
  autoCompletadoBusqueda() {
    this.filteredOptions = this.mySearch.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.nombre)),
      map((nombre) => (nombre ? this._filter(nombre) : this.pedidos.slice()))
    );
  }

  displayFn(user: any): string | undefined {
    return user ? user.nombre : undefined;
  }

  returnFn(user: any): string | undefined {
    return user ? user.value : undefined;
  }

  itemDisplayFn(item: any) {
    return item ? item.name : '';
  }

  private _filter(nombre_distribuidor: string) {
    const filterValue = this.normalizeString(nombre_distribuidor.toLowerCase());
    return this.pedidos.filter(
      (option: any) => this.normalizeString(option.nombre_distribuidor).toLowerCase().indexOf(filterValue) === 0
    );
  }

  /**Ests metodos tienen como objeto ver el detalle de los distribuidores
   * El primer metodo es para la lista completa de distribuidores
   * El segundo metodo es para los distribuidores aprobados */
  verDetalleDistribuidor(event: any) {
    const filtrado = event.option.value._id;
    this.router.navigate(['/pedidos', filtrado]);
  }

  /**
   * Toma el string que entra por parámetro y cambia tildes y diéresis
   * por las letras sin acento, y lo pasa a minúsculas
   * @param pWord El string a filtrar
   * @returns El string filtrado
   */
  normalizeString(pWord: string): string {
    return pWord
      .trim()
      .normalize('NFD')
      .replace(/\p{Diacritic}/gu, '')
      .toLowerCase();
  }
}
