<div id="indicadores" class="p-0 m-0 h-100">
  <div class="notificaciones">
    <div class="card rounded" routerLink="/notificaciones" style="cursor: pointer">
      <p class="main-title" *ngIf="indicadores != undefined">
        <img src="../../../assets/img/icons/icono-24-off.png"
          *ngIf="indicadores.notificaciones == 0 || indicadores.notificaciones == undefined"
          alt="icono notificaciones off" class="icono-indicadores">
        <img src="../../../assets/img/icons/icono-24.png" *ngIf="indicadores.notificaciones > 0"
          alt="icono notificaciones on" class="icono-indicadores">
        &nbsp;&nbsp;{{indicadores.notificaciones || 0}}
      </p>
      <p class="sub-title">Notificaciones</p>
    </div>
  </div>
  <div class="mensajes">
    <div class="card rounded" routerLink="/pedidos/mensajes/-/inicio-distribuidor" style="cursor: pointer">
      <p class="main-title" *ngIf="indicadores != undefined">
        <img src="../../../assets/img/icons/icono-25-off.png"
          *ngIf="indicadores.mensajes == 0 || indicadores.mensajes == undefined" alt="icono mensaje off"
          class="icono-indicadores">
        <img src="../../../assets/img/icons/icono-25.png" *ngIf="indicadores.mensajes > 0" alt="icono mensaje on"
          class="icono-indicadores">
        &nbsp;&nbsp;{{indicadores.mensajes || 0}}
      </p>
      <p class="sub-title">Mensajes sin leer</p>
    </div>
  </div>
  <!--<div class="realizados">
    <div class="card rounded" routerLink="/clientes" style="cursor: pointer">
      <p class="main-title" *ngIf="indicadores != undefined">
        <img src="../../../assets/img/icons/icono-19.png"
          *ngIf="indicadores.solicitudes == 0 || indicadores.solicitudes == undefined" alt="icono pedidos sugeridos off"
          class="icono-indicadores-2">
        <img src="../../../assets/img/icons/icono-19-on.png" *ngIf="indicadores.solicitudes > 0"
          alt="icono pedidos sugeridos on" class="icono-indicadores-2">
        &nbsp;&nbsp;{{indicadores.solicitudes || 0}}
      </p>
      <p class="sub-title">Solicitudes</p>
    </div>
  </div>-->
  <div class="sugeridos">
    <div class="card rounded" routerLink="/pedidos-distribuidor" style="cursor: pointer">
      <p class="main-title" *ngIf="indicadores != undefined">
        <img src="../../../assets/img/icons/icono-26.png" alt="icono pedidos realziados" class="icono-indicadores">
        &nbsp;&nbsp;{{indicadores.pedidos || 0}}
      </p>
      <p class="sub-title">Pedidos nuevos</p>
    </div>
  </div>
  <div class="banner rounded">
    <div class="carousel-container">
      <ngb-carousel *ngIf="banners">
        <ng-template ngbSlide *ngFor="let images of banners">
          <div class="picsum-img-wrapper d-flex justify-content-center align-items-center cursorPointer"
            (click)="goToLink(images.link)">
            <img src="{{images.imagen}}" alt="banner de {{images.titulo}}" class="img-banner">
          </div>
        </ng-template>
      </ngb-carousel>
    </div>
  </div>
</div>