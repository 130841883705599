<app-header-distribuidor></app-header-distribuidor>
<div class="d-flex flex-row justify-content-center" *ngIf="pedido != undefined">
  <div class="contenedor mt-4">
    <div class="w-100">
      <!--Header-->
      <div class="d-flex flex-row justify-content-between align-items-center m-0">
        <!--Btn Volver-->
        <div class="boton-volver rounded mr-3 cursorPointer" [routerLink]="['/pedidos-distribuidor']">
          <fa-icon [icon]="faChevronLeft" [styles]="{ color: '#41403E' }" id="fa-icon" class="mr-2 badge">
          </fa-icon>
          <span>Volver</span>
        </div>
        <!--ID Pedido-->
        <div class="" *ngIf="
            !estado_traducido.startsWith('Cancelado') &&
            !(estado_traducido == 'Recibido' || estado_traducido == 'Entregado' || estado_traducido == 'Calificado')
          ">
          <h3>Pedido en curso ID: {{ pedido?.id_pedido }}</h3>
        </div>
        <div class=""
          *ngIf="estado_traducido == 'Recibido' || estado_traducido == 'Entregado' || estado_traducido == 'Calificado'">
          <h3>Pedido finalizado ID: {{ pedido?.id_pedido }}</h3>
        </div>
        <div class="" *ngIf="estado_traducido.startsWith('Cancelado')">
          <h3>Pedido cancelado ID: {{ pedido?.id_pedido }}</h3>
        </div>
        <!--Comercial encargado-->
        <div class="">
          <div class="card">
            <div class="card-body card-trabajador">
              <div class="row m-0">
                <div class="col-2">
                  <h5 class="initials-vendor" *ngIf="trabajador_horeca">
                    {{trabajador_horeca.nombres.charAt(0).toUpperCase()}}{{trabajador_horeca.apellidos.charAt(0).toUpperCase()}}
                  </h5>
                </div>
                <div class="col-10">
                  <p class="m-0 text-nowrap">{{trabajador_encargado}}</p>
                  <p class="m-0">Comercial encargado</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Información-->
      <div class="card-container row m-0 py-4">
        <!--Estado y totales-->
        <div class="contenedor-totales">
          <div class="card h-100">
            <div class="card-body d-flex flex-column justify-content-betweengn-items-between">
              <!--Estado del pedido-->
              <div class="row m-0">
                <div class="w-100 d-flex flex-row justify-content-between">
                  <fa-icon [icon]="faCircle" style="color: #e9f8ca" *ngIf="
                      !estado_traducido.startsWith('Cancelado') &&
                      !(
                        estado_traducido == 'Recibido' ||
                        estado_traducido == 'Entregado' ||
                        estado_traducido == 'Calificado'
                      )
                    "></fa-icon>
                  <fa-icon [icon]="faCircle" style="color: #97ae22" *ngIf="
                      estado_traducido == 'Recibido' ||
                      estado_traducido == 'Entregado' ||
                      estado_traducido == 'Calificado'
                    "></fa-icon>
                  <fa-icon [icon]="faCircle" style="color: #d38989" *ngIf="estado_traducido.startsWith('Cancelado')">
                  </fa-icon> &nbsp;{{ estado_traducido }}
                  <span>
                    {{ fecha_estado }} a las {{ hora_estado }}
                  </span>
                </div>
              </div>
              <br />
              <!--Barra de estado del pedido si no está cancelado-->
              <div class="row m-0">
                <div class="w-100 p-0" *ngIf="!estado_traducido.startsWith('Cancelado')">
                  <ngb-progressbar type="warning" [max]="5" height="1 rem" [value]="barra_estados">
                    Paso {{ barra_estados }} de 5
                  </ngb-progressbar>
                </div>
                <div class="w-100 p-0" *ngIf="estado_traducido.startsWith('Cancelado')">
                  <ngb-progressbar type="danger" [max]="1" height="1 rem" [value]="1">Pedido cancelado</ngb-progressbar>
                </div>
              </div>
              <br />
              <!--Cambiar estado del pedido-->
              <div class="row m-0" *ngIf="nuevos_estados.length != 0">
                <div class="w-100 p-0">
                  <div class="row m-0">
                    <div class="w-100 p-0">
                      <h6>Estado del pedido</h6>
                    </div>
                  </div>
                  <div class="row m-0">
                    <!--Selector de nuevo estado-->
                    <div class="col-6 p-0 text-center">
                      <select [(ngModel)]="nuevo_estado">
                        <option value="">Selecciona</option>
                        <option *ngFor="let estado of nuevos_estados" [value]="estado">
                          {{ estado }}
                        </option>
                      </select>
                    </div>
                    <!--Btn Cambiar-->
                    <div class="col-6 p-0 text-center">
                      <button class="btn-purple" (click)="cambiarEstado()" [disabled]="nuevo_estado == ''">
                        Cambiar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <!--Scroll de precios-->
              <div class="row m-0">
                <div class="w-100 p-0">
                  <div class="scroll-precios">
                    <!--Info del pedido-->
                    <div class="row m-0">
                      <div class="w-100 p-0">
                        <div (click)="isCollapsed[0] = !isCollapsed[0]" style="cursor: pointer; width: 100%">
                          <h6>
                            Info del pedido
                            <fa-icon [icon]="isCollapsed[0] ? faChevronDown : faChevronUp" size="sm" pull="right">
                            </fa-icon>
                          </h6>
                        </div>
                        <div class="precios-info" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed[0]">
                          <!--Ciudad-->
                          <div class="row m-0">
                            <div class="col-6 p-0 text-left">
                              <h6>Ciudad</h6>
                            </div>
                            <div class="col-6 p-0 text-right">
                              <h6>{{ pedido.ciudad }}</h6>
                            </div>
                          </div>
                          <!--Dirección-->
                          <div class="row m-0">
                            <div class="col-6 p-0 text-left">
                              <h6>Dirección</h6>
                            </div>
                            <div class="col-6 p-0 text-right">
                              <h6>{{ pedido.direccion }}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <!--Valor del pedido-->
                    <div class="row m-0">
                      <div class="w-100 p-0">
                        <div (click)="isCollapsed[1] = !isCollapsed[1]" style="cursor: pointer; width: 100%">
                          <h6>
                            Valor del pedido
                            <fa-icon [icon]="isCollapsed[1] ? faChevronDown : faChevronUp" size="sm" pull="right">
                            </fa-icon>
                          </h6>
                        </div>
                        <div class="precios-info" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed[1]">
                          <!--Subtotal-->
                          <div class="row m-0">
                            <div class="col-6 p-0 text-left">
                              <h6>Subtotal</h6>
                            </div>
                            <div class="col-6 p-0 text-right">
                              <h6>{{ pedido.subtotal_pedido | currency: '$ ':'symbol': '1.0-0' }}</h6>
                            </div>
                          </div>
                          <!--Descuento-->
                          <div class="row m-0">
                            <div class="col-6 p-0 text-left">
                              <h6>Descuento</h6>
                            </div>
                            <div class="col-6 p-0 text-right">
                              <h6 style="color: red">
                                {{ pedido.descuento_pedido > 0 ? '-' : ''
                                }}{{ pedido.descuento_pedido | currency: '$ ':'symbol': '1.0-0' }}
                              </h6>
                            </div>
                          </div>
                          <!--Total-->
                          <div class="row m-0">
                            <div class="col-6 p-0 text-left">
                              <h5>Total</h5>
                            </div>
                            <div class="col-6 p-0 text-right">
                              <h5>{{ pedido.total_pedido | currency: '$ ':'symbol': '1.0-0' }}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <!--Detalles puntos y códigos-->
                    <div class="row m-0">
                      <div class="w-100 p-0">
                        <div class="row m-0">
                          <div class="w-100 p-0">
                            <h6>Detalles</h6>
                          </div>
                        </div>
                        <div class="row m-0">
                          <div class="col-md-5 text-left pad0">
                            <h6>Método de pago:</h6>
                          </div>
                          <div class="col-md-7 text-right pad0">
                            <h6> {{ pedido.metodo_pago || 'Sin Método de pago'}}</h6>
                          </div>
                        </div>


                        <div class="row m-0">
                          <div class="col-12 p-0 text-left">Códigos aprobados por Feat</div>
                          <!--Códigos de descuento usados-->
                          <div class="offset-2 col-10 p-0 text-right" *ngIf="pedido.codigo_descuento.length > 0">
                            <h6 class="pill-yellow text-right p-2" *ngFor="let cod of pedido.codigo_descuento">
                              Código: {{ cod.codigo_creado }}
                            </h6>
                          </div>
                          <!--Estado vacío de códigos de descuento-->
                          <div class="offset-2 col-10 p-0 text-right" *ngIf="pedido.codigo_descuento.length == 0">
                            <h6 class="mb-3 text-secondary">No se usaron códigos de descuento en este pedido</h6>
                          </div>
                        </div>
                        <!--Puntos redimidos-->
                        <div class="row m-0">
                          <div class="w-100 p-0">
                            <h6>{{ pedido.puntos_redimidos }} puntos redimidos</h6>
                          </div>
                        </div>
                        <br />
                        <!--Puntos ganados-->
                        <div class="row m-0">
                          <div class="w-100 p-0">
                            <h6>Tu cliente acumuló {{ pedido.puntos_ganados }} puntos con la compra</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--Cancelar pedido y pre factura-->
              <div class="d-flex flex-row justify-content-between align-items-center m-0 pt-3">
                <!--Cancelar pedido-->
                <div class="col-6 p-0 cursorPointer btnCancela " (click)="popUpCancelarPedido()">
                  <h6 *ngIf="
                        estado_traducido == 'Alistamiento' ||
                        estado_traducido == 'Pendiente' ||
                        estado_traducido == 'Aprobado' ||
                        estado_traducido == 'Aprobado Interno' ||
                        estado_traducido == 'Aprobado Externo'
                    ">
                    <fa-icon [icon]="faTimes"></fa-icon>
                    &nbsp;Cancelar pedido
                  </h6>
                </div>
                <!--Pre factura-->
                <div class="col-6text-right p-0">
                  <button class="btn-purple-transtext-right px-0" routerLink="/pedidos/pre-factura/{{ pedido._id }}">
                    <h6>Pre factura</h6>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--Info del establecimiento-->
        <div class="contenedor-informacion row m-0">
          <div class="card">
            <div class="card-body">
              <!--Informacion general del cliente-->
              <div class="row m-0" *ngIf="info_horeca != undefined && info_punto != undefined">
                <!--Logo del cliente-->
                <div class="col-2 pl-0">
                  <img [src]="info_horeca.logo || '../../../../assets/img/icon-organizacion.png'"
                    style="max-width: 100%; height: auto; border-radius: 12px" alt="Logo del cliente" />
                </div>
                <!--Info general-->
                <div class="col-10 text-left d-flex flex-wrap align-items-between">
                  <!--Nombre del cliente-->
                  <div class="d-flex flex-row justify-content-between align-items-center col-12 p-0">
                    <h5>{{ info_horeca.nombre_establecimiento }}</h5>
                    <!--Btn mensaje al cliente-->
                    <button class="btn-purple-trans pr-0" routerLink="/pedidos/mensajes/{{ id_pedido }}"
                      >
                      <h5>Mensaje al cliente</h5>
                    </button>
                  </div>
                  <!--Tipo de negocio e identificacion-->
                  <div class="d-flex flex-row justify-content-between align-items-center col-12 p-0">
                    <h6 class="pill-grey">{{ info_horeca.tipo_negocio || '' }}</h6>
                    <!--Identificacion del cliente-->
                    <h6>
                      {{info_horeca.tipo_usuario == 'Empresa' ? 'NIT' : 'CC'}}: {{ info_horeca.nit || 'No registra'
                      }}
                    </h6>
                  </div>
                </div>
                <!--Convenio y estado de cartera-->
                <div class="d-flex flex-row justify-content-start align-items-center col-12 px-0 py-3"
                  *ngIf="info_vinculacion && (info_vinculacion.convenio || info_vinculacion.pazysalvo)">
                  <!--Convenio de precios-->
                  <h6 class="pill-yellow mr-3 px-4" *ngIf="info_vinculacion.convenio">Convenio de precios</h6>
                  <!--Estado de cartera-->
                  <h6 class="pill-lavender px-4" *ngIf="info_vinculacion.pazysalvo">A paz y salvo</h6>
                </div>
                <!--Sede que realizo el pedido-->
                <div class="d-flex flex-row justify-content-between align-items-center col-12 p-0">
                  <h6 class="m-0 pb-2">
                    <fa-icon [icon]="faMapMarkerAlt"></fa-icon>
                    &nbsp;{{ info_punto.nombre }} - dirección: {{ info_punto.direccion }}
                  </h6>
                </div>
                <!--Btn información del encargado-->
                <div class="d-flex flex-row justify-content-start align-items-center col-12 px-0">
                  <button class="btn-purple-trans pl-0 pb-0" (click)="openModalInfoEncargado()">
                    Información del encargado
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--Listado de productos-->
        <div class="contenedor-productos row m-0" *ngIf="productos.length != 0">
          <div class="col-12 p-0">
            <div class="card">
              <div class="card-body">
                <!--Header listado de productos-->
                <div class="row d-flex align-items-center m-0">
                  <!--Resumen-->
                  <div class="col-9">
                    <p class="m-0">
                      {{ productos.length }} producto{{ productos.length != 1 ? 's' : '' }} -
                      {{ unidades_totales }} unidad{{ unidades_totales != 1 ? 'es' : '' }} en tu pedido
                    </p>
                  </div>
                  <!--Btn editar-->
                    <div class="col-3" *ngIf="validacionBotonEditar " >
                    <!-- (click)="editarPedDist()"-->
                    <!--<button class="btn-grey p-3"
                    (click)="editar()">
                      <h6 class="m-0">
                        &nbsp;Editar
                      </h6>
                    </button>-->
                  </div>
                </div>
                <!--Scroll de productos-->
                <div class=" row m-0">
                  <div class="col-12">
                    <div class="scroll-prods">
                      <br />
                      <!--Producto individual-->
                      <div class="prod" *ngFor="let prod of productos">
                        <div class="row m-0">
                          <!--Imagen-->
                          <div class="d-flex justify-content-center col-3 pl-0">
                            <img *ngIf="prod.promocion === false" class="img-producto" [src]="prod.fotos[0]"
                              alt="Producto" />
                            <div class="row carouselCont" *ngIf="prod.promocion === true">
                              <ngb-carousel class="w-100">
                                <ng-template ngbSlide *ngFor="let img_src of prod.fotos" class="w-100">
                                  <div class="w-100 col-12 d-flex justify-content-center">
                                    <img [src]="img_src" class="img-prod rounded" [alt]="'Foto de ' + prod.nombre" />
                                  </div>
                                </ng-template>
                              </ngb-carousel>
                            </div>
                          </div>
                          <!--Informacion del producto-->
                          <div class="col-6 d-flex flex-column justify-content-between">
                            <strong class="m-0">{{ prod.nombre }}</strong>
                            <div *ngIf="prod.precioEspecial.length > 0">

                               <!-- Precio por unidad -->
                            <p class="m-0">
                              {{ prod.precioEspecial[0].precioActual  | currency: '$ ':'symbol':'1.0-0' }}und.
                            </p>
                            <!-- Precio Caja -->
                            <p class="m-0">
                              {{ prod.precioEspecial[0].precioActual * prod.precios[0].und_x_caja | currency : '$ ' : 'symbol' : '1.0-0' }}caja.
                            </p>
                            <!-- unidad de medida y cantidad medida -->
                            <p class="m-0" *ngIf="prod.promocion === false && prod.saldos === false && !prod.porcentaje_descuento">
                              {{
                                prod.precioEspecial[0].precioActual / prod.precios[0].cantidad_medida
                                  | currency : '$ ' : 'symbol' : '1.0-0'
                              }}/{{ prod.precios[0].unidad_medida }}
                            </p>



                            </div>
                            <div *ngIf="prod.precioEspecial.length === 0">
                               <!-- Precio por unidad -->
                            <p class="m-0" *ngIf="prod.saldos === false && !prod.porcentaje_descuento">
                              {{ prod.precios[0].precio_unidad | currency: '$ ':'symbol':'1.0-0' }}und.
                            </p>
                            <p class="m-0" *ngIf="prod.saldos === false && prod.porcentaje_descuento">
                              {{ prod.precio_original | currency : '$ ' : 'symbol' : '1.0-0' }}und.
                            </p>
                            <p class="m-0" *ngIf="prod.saldos === true">
                              {{ prod.precios[0].precio_descuento | currency: '$ ':'symbol':'1.0-0' }}und.
                            </p>
                            <!-- Precio Caja -->
                            <p class="m-0" *ngIf="prod.promocion === false && prod.saldos === false && !prod.porcentaje_descuento">
                              {{ prod.precios[0].precio_caja | currency: '$ ':'symbol':'1.0-0' }}caja.
                            </p>
                            <p class="m-0" *ngIf="prod.promocion === false && prod.saldos === false && prod.porcentaje_descuento">
                              {{ prod.precio_caja | currency : '$ ' : 'symbol' : '1.0-0' }}caja.
                            </p>
                            <!-- unidad de medida y cantidad medida -->
                            <p class="m-0" *ngIf="prod.promocion === false && prod.saldos === false && !prod.porcentaje_descuento">
                              {{
                              prod.precios[0].precio_unidad / prod.precios[0].cantidad_medida
                              | currency: '$ ':'symbol':'1.0-0'
                              }}/{{ prod.precios[0].unidad_medida }}
                            </p>
                            <p class="m-0" *ngIf="prod.promocion === false && prod.saldos === false && prod.porcentaje_descuento">
                              {{
                                prod.precio_original / prod.precios[0].cantidad_medida
                                  | currency : '$ ' : 'symbol' : '1.0-0'
                              }}/{{ prod.precios[0].unidad_medida }}
                            </p>
                            <p class="m-0" *ngIf="prod.saldos === true">
                              {{
                              prod.precios[0].precio_descuento / prod.precios[0].cantidad_medida
                              | currency: '$ ':'symbol':'1.0-0'
                              }}/{{ prod.precios[0].unidad_medida }}
                            </p>

                            </div>
                           


                            <!-- Codigos producto -->
                            <p class="m-0" *ngIf="prod.promocion === false">
                              Código distribuidor: {{ prod.codigo_distribuidor_producto }}
                            </p>
                            <p class="m-0" *ngIf="prod.promocion === false && prod.saldos === true">
                              Código saldos: {{ prod.codigo_promo }}
                            </p>
                            <p class="m-0" *ngIf="prod.promocion === true">
                              Código promoción: {{ prod.codigo_promo }}
                            </p>
                            <!-- Puntos ft por producto -->
                            <p style="font-size: 0.9rem" class="m-0"
                              *ngIf="prod.promocion === false && prod.saldos === false">{{
                              prod.puntos_ft_unidad }} pts FT</p>
                          </div>
                          <!--Totales-->
                          <div class="col-3 text-right d-flex flex-column justify-content-between">
                            <!--Unidades-->
                            <h6>
                              <strong class="m-0">
                                {{ prod.unidades_compradas }} und{{ prod.unidades_compradas !== 1 ? 's' : '' }}.
                              </strong>
                              <p class="m-0 mt-2" *ngIf="prod.promocion === false">
                                Total en cajas: {{ converitrUnidadesACajad(prod.unidades_compradas,
                                prod.precios[0].und_x_caja) }}
                              </p>
                            </h6>
                            <!--Precio total-->

                            <div *ngIf="prod.precioEspecial.length > 0">

                              <h6 class="precio-prod">
                                <strong>
                                  Total:
                                  <span >
                                    {{ prod.precioEspecial[0].precioActual* prod.unidades_compradas | currency: '$' : 'symbol' :
                                    '1.0-0' }}
                                  </span>
                                </strong>
                              </h6>


                            </div>
                            <div *ngIf="prod.precioEspecial.length === 0">
                              <h6 class="precio-prod">
                                <strong>
                                  Total:
                                  <span *ngIf="prod.saldos === false && !prod.prodDescuento">
                                    {{prod.precios[0].precio_unidad * prod.unidades_compradas | currency: '$' : 'symbol' :
                                    '1.0-0' }}
                                  </span>
                                  <span *ngIf="prod.saldos === false && prod.prodDescuento">
                                    Total:
                                    {{ prod.precio_original * prod.unidades_compradas | currency : '$ ' : 'symbol': '1.0-0' }}
                                  </span>
                                  <span *ngIf="prod.saldos === true">
                                    {{prod.precios[0].precio_descuento * prod.unidades_compradas | currency: '$' :
                                    'symbol' : '1.0-0' }}
                                  </span>
                                </strong>
                              </h6>
                            </div>
                        


                          </div>
                        </div>
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>