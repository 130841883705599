<div class="w-100 p-4">
  <div class="w-100 d-flex flex-row justify-content-between align-items-center">
    <h4>
      Puntos asignados
    </h4>
    <fa-icon style="cursor: pointer;" [icon]="faTimes" [styles]="{'color': '#41403E'}" size="lg" (click)="onClose()">
    </fa-icon>
  </div>
  <div class="w-100 p-1 mt-2">
    <label>Nuevo punto de {{tipo_usuario === 'establecimiento' ? 'entrega' : 'venta' }}</label><br>
    
    <!--<select name="select" class="seleccionar-trabajador p-2 w-100" multiple>
      <option *ngFor="let punto_entrega of puntos_entrega; index as i;" (click)="seleccionarTrabajador(punto_entrega)">
        <span *ngIf="this.tipo_usuario === 'distribuidor'">{{nombre_establecimientos_por_punto[i]}} | </span> {{
        puntos_entrega[i].nombre }}
      </option>
    </select>
  -->
    <select name="select" class="seleccionar-trabajador p-2 w-100"  (change)="seleccionarTrabajador($event)" >
      <option *ngFor="let punto_entrega of puntos_entrega; index as i;"  [value]="i">
        <span *ngIf="this.tipo_usuario === 'distribuidor'">{{puntos_entrega[i].nameSelect}} | </span> {{
        puntos_entrega[i].nombre }} 
      </option>
    </select>
  </div>
  <div class="d-flex w-100 flex-wrap">
    <label class="col-12 my-3 px-0">
      Tus puntos de {{tipo_usuario === 'establecimiento' ? 'entrega' : 'venta'}}
    </label>
    <br>
    <div class="col-6 px-1 d-flex justify-content-between align-items-center px-1 padbotton"
      *ngFor="let punto_entrega of copyDataPuntos; index as i;">
      <h6 class="d-flex align-content-center m-0 font-weight-light">
        {{ punto_entrega.nombre_punto ? punto_entrega.nombre_punto : punto_entrega.nombre  }}
      </h6>
      <button class="trash" (click)="elminarTrabajador(punto_entrega, i)">
        <fa-icon [icon]="faTrashAlt" id="fa-icon"></fa-icon>
      </button>
    </div>
  </div>
  <div class="w-100 mt-4">
    <div class="row text-center">
      <div class="col-6 d-flex justify-content-center">
        <span
          class="d-flex justify-content-center align-items-center btn btn-ligh border border-secondary text-warning w-75 h-100"
          (click)="onClose()">
          Cancelar
        </span>
      </div>
      <div class="col-6">
        <button id="btn_modal" class="w-75" (click)="guardarCambios()">
          Listo
        </button>
      </div>
    </div>
  </div>
</div>