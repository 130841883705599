import { LOCALE_ID, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';

import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatSliderModule } from '@angular/material/slider';

// import { NoopAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { BlogComponent } from './blog/blog.component';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './header/header.component';
import { HeaderRegisterComponent } from './header-register/header-register.component';
import { BannerComponent } from './home/banner/banner.component';
import { MiddleBannerComponent } from './home/middle-banner/middle-banner.component';
import { UltimasNoticiasComponent } from './home/ultimas-noticias/ultimas-noticias.component';
import { RegistroComponent } from './login/registro/registro.component';
import { RegistroHorecaComponent } from './login/registro/registro-horeca/registro-horeca.component';
import { RegistroDistribuidorComponent } from './login/registro/registro-distribuidor/registro-distribuidor.component';
import { BannerLoginComponent } from './login/banner-login/banner-login.component';
import { FormLoginComponent } from './login/form-login/form-login.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SimpleComponent } from './modal/simple/simple.component';
import { InicioComponent } from './inicio/inicio.component';
import { InicioPerfilComponent } from './inicio/inicio-perfil/inicio-perfil.component';
import { InicioComunidadComponent } from './inicio/inicio-comunidad/inicio-comunidad.component';
import { InicioIndicadoresComponent } from './inicio/inicio-indicadores/inicio-indicadores.component';
import { InicioDistribuidoresConectadosComponent } from './inicio/inicio-distribuidores-conectados/inicio-distribuidores-conectados.component';
import { SimpleNoImgComponent } from './modal/simple-no-img/simple-no-img.component';
import { MultipasoComponent } from './modal/multipaso/multipaso.component';
import { MultipasoFormPuntoVentaComponent } from './modal/multipaso-form-punto-venta/multipaso-form-punto-venta.component';
import { AgmCoreModule } from '@agm/core';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MiCuentaComponent } from './mi-cuenta/mi-cuenta.component';
import { TrabajadoresComponent } from './mi-cuenta/trabajadores/trabajadores.component';
import { InformacionUsuarioComponent } from './mi-cuenta/informacion-usuario/informacion-usuario.component';
import { CrearTrabajadorComponent } from './modal/crear-trabajador/crear-trabajador.component';
import { PuntosEntregaComponent } from './mi-cuenta/puntos-entrega/puntos-entrega.component';
import { HeaderHorecaComponent } from './header-horeca/header-horeca.component';
import { GestionarComponent } from './mi-cuenta/trabajadores/gestionar/gestionar.component';
import { InformacionCuentaComponent } from './mi-cuenta/informacion-cuenta/informacion-cuenta.component';
import { GraficasComponent } from './mi-cuenta/graficas/graficas.component';
import { EditarPuntosAsignadosComponent } from './modal/editar-puntos-asignados/editar-puntos-asignados.component';
import { DistribuidoresBuscadorComponent } from './distribuidores/distribuidores-buscador/distribuidores-buscador.component';
import { DistribuidoresGraficasComponent } from './distribuidores/distribuidores-graficas/distribuidores-graficas.component';
import { FiltroDistribuidoresComponent } from './modal/filtro-distribuidores/filtro-distribuidores.component';
import { SeleccionarPuntoEntregaComponent } from './modal/seleccionar-punto-entrega/seleccionar-punto-entrega.component';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { RecordarClaveComponent } from './login/recordar-clave/recordar-clave.component';
import { ConfirmacionComponent } from './modal/confirmacion/confirmacion.component';
import { SolicitudComponent } from './mi-cuenta/trabajadores/solicitud/solicitud.component';
import { DistribuidoresComponent } from './distribuidores/distribuidores.component';
import { DetalleDistribuidorComponent } from './distribuidores/detalle-distribuidor/detalle-distribuidor.component';
import { DetalleDistribuidorNoVinculadoComponent } from './distribuidores/detalle-distribuidor/detalle-distribuidor-no-vinculado/detalle-distribuidor-no-vinculado.component';
import { DetallePuntoEntregaComponent } from './mi-cuenta/puntos-entrega/detalle-punto-entrega/detalle-punto-entrega.component';
import { NgxsModule } from '@ngxs/store';
import { TokenState } from './states/token.state';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { PedidosComponent } from './pedidos/pedidos.component';
import { PedidosEnCursoComponent } from './pedidos/pedidos-en-curso/pedidos-en-curso.component';
import { HistorialPedidosComponent } from './pedidos/historial-pedidos/historial-pedidos.component';
import { GraficasPedidosComponent } from './pedidos/graficas-pedidos/graficas-pedidos.component';
import { CambiarContrasenaComponent } from './modal/cambiar-contrasena/cambiar-contrasena.component';
import { DetalleProductoComponent } from './distribuidores/detalle-producto/detalle-producto.component';
import { CarritoComponent } from './carrito/carrito.component';
import { MisDistribuidoresComponent } from './distribuidores/mis-distribuidores/mis-distribuidores.component';
import { DetallePedidoComponent } from './pedidos/detalle-pedido/detalle-pedido.component';
import { DetallePedidoSugeridoComponent } from './pedidos/detalle-pedido-sugerido/detalle-pedido-sugerido.component';
import { MensajesPedidosComponent } from './pedidos/mensajes-pedidos/mensajes-pedidos.component';
import { HeaderDistribuidorComponent } from './header-distribuidor/header-distribuidor.component';
import { InicioDistribuidorComponent } from './inicio-distribuidor/inicio-distribuidor.component';
import { InicioPerfilDistComponent } from './inicio-distribuidor/inicio-perfil-dist/inicio-perfil-dist.component';
import { InicioIndicadoresDistComponent } from './inicio-distribuidor/inicio-indicadores-dist/inicio-indicadores-dist.component';
import { PuntosFeatComponent } from './puntos-feat/puntos-feat.component';
import { PuntosComprasComponent } from './puntos-feat/puntos-compras/puntos-compras.component';
import { RedencionesComponent } from './puntos-feat/redenciones/redenciones.component';
import { InformesComponent } from './informes/informes.component';
import { InformesGeneralComponent } from './informes/informes-general/informes-general.component';
import { GraficasGeneralComponent } from './informes/graficas-general/graficas-general.component';
import { ComunidadComponent } from './comunidad/comunidad.component';
import { PublicacionesComponent } from './comunidad/publicaciones/publicaciones.component';
import { PortafolioComponent } from './portafolio/portafolio.component';
import { AgregarProductoComponent } from './portafolio/agregar-producto/agregar-producto.component';
import { PublicarPublicacionComponent } from './modal/publicar-publicacion/publicar-publicacion.component';
import { SolicitudesDistribuidorComponent } from './distribuidores/solicitudes-distribuidor/solicitudes-distribuidor.component';
import { CatalogoComponent } from './portafolio/catalogo/catalogo.component';
import { InventarioComponent, BtnEditarInv } from './portafolio/inventario/inventario.component';
import { GraficasPortafolioComponent } from './portafolio/graficas-portafolio/graficas-portafolio.component';
import { SaldosPromocionesComponent } from './inicio/saldos-promociones/saldos-promociones.component';
import { VideoComponent } from './comunidad/video/video.component';
import { EditarProductoComponent } from './portafolio/catalogo/editar-producto/editar-producto.component';
import { PreciosComponent } from './precios/precios.component';
import { CargarProductoExcelComponent } from './modal/cargar-producto-excel/cargar-producto-excel.component';
import { ProcesandoInfoComponent } from './modal/procesando-info/procesando-info.component';
import { CargarCatalogoComponent } from './modal/cargar-catalogo/cargar-catalogo.component';
import { CalificacionPedidoComponent } from './modal/calificacion-pedido/calificacion-pedido.component';
import { DateDiffPipe } from './pipes/date-diff.pipe';
import { ClientesComponent } from './clientes/clientes.component';
import { ClientesInfoComponent } from './clientes/clientes-info/clientes-info.component';
import { ClientesGraficasComponent } from './clientes/clientes-graficas/clientes-graficas.component';
import { DetalleClientesComponent } from './clientes/detalle-clientes/detalle-clientes.component';
import { AprobarrechazarDistribuidoresComponent } from './modal/aprobarrechazar-distribuidores/aprobarrechazar-distribuidores.component';
import { EditarInventarioProductoComponent } from './modal/editar-inventario-producto/editar-inventario-producto.component';
import { CargandoGenericoComponent } from './modal/cargando-generico/cargando-generico.component';
import { SaldosPromosDistribuidorComponent } from './saldos-promos-distribuidor/saldos-promos-distribuidor.component';
import {
  SYPCrearNuevoComponent,
  BtnCrearSaldo,
  ChkCrearPromo,
} from './saldos-promos-distribuidor/s-y-p-crear-nuevo/s-y-p-crear-nuevo.component';
import { SYPCreadosComponent } from './saldos-promos-distribuidor/s-y-p-creados/s-y-p-creados.component';
import { PopUpSaldosPromosComponent } from './modal/pop-up-saldos-promos/pop-up-saldos-promos.component';
import { PedidosDistribuidorComponent } from './pedidos-distribuidor/pedidos-distribuidor.component';
import { BtnEditarPedidoDist, PedidosInfoComponent } from './pedidos-distribuidor/pedidos-info/pedidos-info.component';
import { PedidosSugeridosComponent } from './pedidos-distribuidor/pedidos-sugeridos/pedidos-sugeridos.component';
import { PedidosGraficasComponent } from './pedidos-distribuidor/pedidos-graficas/pedidos-graficas.component';
import { CurrencyPipe } from '@angular/common';
import { CrearSaldoComponent } from './modal/crear-saldo/crear-saldo.component';
import { EditarSaldoComponent } from './modal/editar-saldo/editar-saldo.component';
import { CuentaDistribuidorComponent } from './cuenta-distribuidor/cuenta-distribuidor.component';
import { InformacionDistribuidorComponent } from './cuenta-distribuidor/informacion-distribuidor/informacion-distribuidor.component';
import { TrabajadoresDistribuidorComponent } from './cuenta-distribuidor/trabajadores-distribuidor/trabajadores-distribuidor.component';
import { CrearPromoComponent } from './modal/crear-promo/crear-promo.component';
import { EditarPromoComponent } from './modal/editar-promo/editar-promo.component';
import { InformacionEmpresaComponent } from './cuenta-distribuidor/informacion-empresa/informacion-empresa.component';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { GraficasDistribuidorComponent } from './cuenta-distribuidor/graficas-distribuidor/graficas-distribuidor.component';
import { InformacionCuentaDistribuidorComponent } from './cuenta-distribuidor/informacion-cuenta-distribuidor/informacion-cuenta-distribuidor.component';
import { NoticiaComponent } from './blog/noticia/noticia.component';
import { PreAprobarClienteComponent } from './modal/pre-aprobar-cliente/pre-aprobar-cliente.component';
import { FutureSectionComponent } from './home/future-section/future-section.component';
import { EspecificacionFeatComponent } from './home/especificacion-feat/especificacion-feat.component';
import { MisionComponent } from './home/mision/mision.component';
import { PlanesClientesComponent } from './home/planes-clientes/planes-clientes.component';
import { ExperienciaFeatComponent } from './home/experiencia-feat/experiencia-feat.component';
import { MobileComponent } from './home/mobile/mobile.component';
import { OrganizacionesLandingComponent } from './home/organizaciones-landing/organizaciones-landing.component';
import { ContactoComponent } from './home/contacto/contacto.component';
import { FooterComponent } from './home/footer/footer.component';
import { DetalleTrabajadorAprobadoComponent } from './cuenta-distribuidor/trabajadores-distribuidor/detalle-trabajador-aprobado/detalle-trabajador-aprobado.component';
import { SolicitudTrabajadorComponent } from './cuenta-distribuidor/trabajadores-distribuidor/solicitud-trabajador/solicitud-trabajador.component';
import { NuevoTrabajadorComponent } from './modal/nuevo-trabajador/nuevo-trabajador.component';
import { DetallePedidoDistComponent } from './pedidos-distribuidor/pedidos-info/detalle-pedido-dist/detalle-pedido-dist.component';
import { CookieService } from 'ngx-cookie-service';
import { PuntosFtComponent } from './puntos-ft/puntos-ft.component';
import { TablaPuntosFtComponent } from './puntos-ft/tabla-puntos-ft/tabla-puntos-ft.component';
import { GraficaPuntosFtComponent } from './puntos-ft/grafica-puntos-ft/grafica-puntos-ft.component';
import { PreFacturaPedidoComponent } from './pedidos/pre-factura-pedido/pre-factura-pedido.component';
import { InformesDistribuidorComponent } from './informes-distribuidor/informes-distribuidor.component';
import { TablasInformesDistribuidorComponent } from './informes-distribuidor/tablas-informes-distribuidor/tablas-informes-distribuidor.component';
import { InformesSectorComponent } from './informes-distribuidor/informes-sector/informes-sector.component';
import { GraficaVentasComponent } from './informes-distribuidor/grafica-ventas/grafica-ventas.component';
import { PedidosSugHistorialComponent } from './pedidos-distribuidor/pedidos-sugeridos/pedidos-sug-historial/pedidos-sug-historial.component';
import { ChequeoPreciosDistComponent } from './inicio-distribuidor/inicio-perfil-dist/chequeo-precios-dist/chequeo-precios-dist.component';
import { CambiarCiudadComponent } from './modal/cambiar-ciudad/cambiar-ciudad.component';
import { SeleccionaPlanDistComponent } from './inicio-distribuidor/selecciona-plan-dist/selecciona-plan-dist.component';
import { ActualizaPlanComponent } from './inicio-distribuidor/selecciona-plan-dist/actualiza-plan/actualiza-plan.component';
import { HeaderOrganizacionComponent } from './organizaciones/header-organizacion/header-organizacion.component';
import { InicioOrganizacionComponent } from './organizaciones/inicio-organizacion/inicio-organizacion.component';
import { PortafolioOrganizacionComponent } from './organizaciones/portafolio-organizacion/portafolio-organizacion.component';
import { DetalleProductoOrganizacionComponent } from './organizaciones/portafolio-organizacion/detalle-producto/detalle-producto.component';
import { GraficasOrganizacionComponent } from './organizaciones/portafolio-organizacion/graficas-organizacion/graficas-organizacion.component';
import { ContenidoPortafolioComponent } from './organizaciones/portafolio-organizacion/contenido-portafolio/contenido-portafolio.component';
import { PuntosFtOrganizacionComponent } from './organizaciones/puntos-ft-organizacion/puntos-ft-organizacion.component';
import { PuntosftGraficasOrganizacionComponent } from './organizaciones/puntos-ft-organizacion/puntosft-graficas-organizacion/puntosft-graficas-organizacion.component';
import { PuntosComprasOrganizacionComponent } from './organizaciones/puntos-ft-organizacion/puntos-compras-organizacion/puntos-compras-organizacion.component';
import { InicioTopProductosComponent } from './inicio-distribuidor/inicio-top-productos/inicio-top-productos.component';
import { InicioInformesDistComponent } from './inicio-distribuidor/inicio-informes-dist/inicio-informes-dist.component';
import { environment } from 'src/environments/environment';
import { TopProductoCardComponent } from './inicio-distribuidor/inicio-top-productos/components/top-producto-card/top-producto-card.component';
import { SeleccionarCiudadesComponent } from './modal/seleccionar-ciudades/seleccionar-ciudades.component';
import { DistribuidoresOrganizacionesComponent } from './organizaciones/distribuidores-organizaciones/distribuidores-organizaciones.component';
import { ContenidoDistribuidoresComponent } from './organizaciones/distribuidores-organizaciones/contenido-distribuidores/contenido-distribuidores.component';
import { GrafiacasDistribuidoresOrgComponent } from './organizaciones/distribuidores-organizaciones/grafiacas-distribuidores-org/grafiacas-distribuidores-org.component';
import { InfoAdicionalClienteComponent } from './clientes/detalle-clientes/components/info-adicional-cliente/info-adicional-cliente.component';
import { CargarClientesExcelComponent } from './modal/cargar-clientes-excel/cargar-clientes-excel.component';
import { ProcesandoInfoClientesComponent } from './modal/procesando-info-clientes/procesando-info-clientes.component';
import { CuentaOrganizacionesComponent } from './organizaciones/cuenta-organizaciones/cuenta-organizaciones.component';
import { TrabajadoresOrganizacionesComponent } from './organizaciones/cuenta-organizaciones/trabajadores-organizaciones/trabajadores-organizaciones.component';
import { InfoCuentaOrganizacionesComponent } from './organizaciones/cuenta-organizaciones/info-cuenta-organizaciones/info-cuenta-organizaciones.component';
import { HistorialPagosOrganizacionesComponent } from './organizaciones/cuenta-organizaciones/historial-pagos-organizaciones/historial-pagos-organizaciones.component';
import { SolicitudCardComponent } from './clientes/clientes-info/components/solicitud-card/solicitud-card.component';
import { SolicitudesComponent } from './clientes/solicitudes/solicitudes.component';
import { DetalleTrabajadorOrgComponent } from './organizaciones/cuenta-organizaciones/trabajadores-organizaciones/detalle-trabajador-org/detalle-trabajador-org.component';
import { ExpandirPlanOrgComponent } from './organizaciones/cuenta-organizaciones/expandir-plan-org/expandir-plan-org.component';
import { PlanesCardComponent } from './inicio-distribuidor/selecciona-plan-dist/planes-card/planes-card.component';
import { RespuestaPagoPlanComponent } from './inicio-distribuidor/selecciona-plan-dist/respuesta-pago-plan/respuesta-pago-plan.component';
import { ExpandirPuntosOrganizacionComponent } from './organizaciones/puntos-ft-organizacion/expandir-puntos-organizacion/expandir-puntos-organizacion.component';
import { PortafolioDistribuidorOrgComponent } from './organizaciones/distribuidores-organizaciones/contenido-distribuidores/portafolio-distribuidor-org/portafolio-distribuidor-org.component';
import { CargarImagenMensajesComponent } from './modal/cargar-imagen-mensajes/cargar-imagen-mensajes.component';
import { ChatDistribuidoresOrganizacionComponent } from './organizaciones/distribuidores-organizaciones/contenido-distribuidores/chat-distribuidores-organizacion/chat-distribuidores-organizacion.component';
import { DetalleProductoDistribuidoresOrganizacionComponent } from './organizaciones/distribuidores-organizaciones/contenido-distribuidores/detalle-producto-distribuidores-organizacion/detalle-producto-distribuidores-organizacion.component';
import { NoticiaCardComponent } from './blog/noticia-card/noticia-card.component';

import localeES from '@angular/common/locales/es';
import { RegistrarMiembroOrganizacionComponent } from './modal/registrar-miembro-organizacion/registrar-miembro-organizacion.component';
import { CalificacionesDistribuidorComponent } from './modal/calificaciones-distribuidor/calificaciones-distribuidor.component';
import { InformacionEncargadoPuntoComponent } from './modal/informacion-encargado-punto/informacion-encargado-punto.component';
import { PuntosCardComponent } from './clientes/detalle-clientes/components/puntos-card/puntos-card.component';
import { InformesOrganizacionesComponent } from './organizaciones/informes-organizaciones/informes-organizaciones.component';
import { InformesGeneralOrganizacionesComponent } from './organizaciones/informes-organizaciones/informes-general-organizaciones/informes-general-organizaciones.component';
import { GraficasVentasOrganizacionesComponent } from './organizaciones/informes-organizaciones/graficas-ventas-organizaciones/graficas-ventas-organizaciones.component';
import { SectorComponent } from './organizaciones/sector/sector.component';
import { EstablecimientosOrganizacionesComponent } from './organizaciones/establecimientos-organizaciones/establecimientos-organizaciones.component';
import { GraficaDetalleDistribuidorComponent } from './distribuidores/detalle-distribuidor/grafica-detalle-distribuidor/grafica-detalle-distribuidor.component';
import { ChatComponent } from './chat/chat.component';
import { ProdSugeridosAddComponent } from './modal/prod-sugeridos-add/prod-sugeridos-add.component';
import { ComparandoDistribuidoresComponent } from './modal/comparando-distribuidores/comparando-distribuidores.component';
import { NewCarritoComponent } from './carrito/new-carrito/new-carrito.component';
import { PoliticasComponent } from './politicas/politicas.component';
import { DetalleProductoFlotanteComponent } from './modal/detalle-producto-flotante/detalle-producto-flotante.component';
import { ModalPlanComponent } from './modal/modal-plan/modal-plan.component';
import { InterceptorInterceptor } from './services/interceptor.interceptor';
import { EditarPedidoDistComponent } from './pedidos/editar-pedido-dist/editar-pedido-dist.component';
import { PortafolioDistConveniosComponent } from './modal/portafolio-dist-convenios/portafolio-dist-convenios.component';
import { FinishRegisterComponent } from './newClients/finish-register/finish-register.component';
import { ClientesNuevosComponent } from './clientes/clientes-nuevos/clientes-nuevos.component';
import { ClientesActivosComponent } from './clientes/clientes-activos/clientes-activos.component';
import { ClientesCreadosComponent } from './clientes/clientes-creados/clientes-creados.component';
import { PedidosEditarComponent } from './pedidos-distribuidor/pedidos-editar/pedidos-editar.component';

registerLocaleData(localeES);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    InicioOrganizacionComponent,
    BlogComponent,
    LoginComponent,
    HeaderComponent,
    BannerComponent,
    MiddleBannerComponent,
    UltimasNoticiasComponent,
    RegistroComponent,
    RegistroHorecaComponent,
    RegistroDistribuidorComponent,
    BannerLoginComponent,
    FormLoginComponent,
    SimpleComponent,
    InicioComponent,
    InicioPerfilComponent,
    InicioComunidadComponent,
    InicioIndicadoresComponent,
    InicioDistribuidoresConectadosComponent,
    SimpleNoImgComponent,
    MultipasoComponent,
    MultipasoFormPuntoVentaComponent,
    MiCuentaComponent,
    TrabajadoresComponent,
    InformacionUsuarioComponent,
    CrearTrabajadorComponent,
    PuntosEntregaComponent,
    HeaderHorecaComponent,
    HeaderRegisterComponent,
    GestionarComponent,
    InformacionCuentaComponent,
    GraficasComponent,
    EditarPuntosAsignadosComponent,
    DistribuidoresComponent,
    DistribuidoresBuscadorComponent,
    DistribuidoresGraficasComponent,
    FiltroDistribuidoresComponent,
    SeleccionarPuntoEntregaComponent,
    RecordarClaveComponent,
    ConfirmacionComponent,
    SolicitudComponent,
    PedidosComponent,
    PedidosEnCursoComponent,
    HistorialPedidosComponent,
    GraficasPedidosComponent,
    DetalleDistribuidorComponent,
    DetalleDistribuidorNoVinculadoComponent,
    DetallePuntoEntregaComponent,
    CambiarContrasenaComponent,
    DetalleProductoComponent,
    CarritoComponent,
    MisDistribuidoresComponent,
    DetallePedidoComponent,
    DetallePedidoSugeridoComponent,
    MensajesPedidosComponent,
    HeaderDistribuidorComponent,
    InicioDistribuidorComponent,
    InicioPerfilDistComponent,
    InicioIndicadoresDistComponent,
    PuntosFeatComponent,
    PuntosComprasComponent,
    RedencionesComponent,
    InformesComponent,
    InformesGeneralComponent,
    GraficasGeneralComponent,
    ComunidadComponent,
    PublicacionesComponent,
    PortafolioComponent,
    AgregarProductoComponent,
    PublicarPublicacionComponent,
    SolicitudesDistribuidorComponent,
    CatalogoComponent,
    InventarioComponent,
    GraficasPortafolioComponent,
    SaldosPromocionesComponent,
    VideoComponent,
    EditarProductoComponent,
    PreciosComponent,
    CargarProductoExcelComponent,
    ProcesandoInfoComponent,
    CargarCatalogoComponent,
    CalificacionPedidoComponent,
    DateDiffPipe,
    ClientesComponent,
    ClientesInfoComponent,
    ClientesGraficasComponent,
    DetalleClientesComponent,
    AprobarrechazarDistribuidoresComponent,
    EditarInventarioProductoComponent,
    CargandoGenericoComponent,
    BtnEditarInv,
    SaldosPromosDistribuidorComponent,
    SYPCrearNuevoComponent,
    BtnCrearSaldo,
    ChkCrearPromo,
    SYPCreadosComponent,
    PopUpSaldosPromosComponent,
    PedidosDistribuidorComponent,
    PedidosInfoComponent,
    BtnEditarPedidoDist,
    PedidosSugeridosComponent,
    PedidosGraficasComponent,
    CrearSaldoComponent,
    EditarSaldoComponent,
    CuentaDistribuidorComponent,
    InformacionDistribuidorComponent,
    TrabajadoresDistribuidorComponent,
    CrearPromoComponent,
    EditarPromoComponent,
    InformacionEmpresaComponent,
    NotificacionesComponent,
    GraficasDistribuidorComponent,
    InformacionCuentaDistribuidorComponent,
    NoticiaComponent,
    PreAprobarClienteComponent,
    FutureSectionComponent,
    EspecificacionFeatComponent,
    MisionComponent,
    PlanesClientesComponent,
    ExperienciaFeatComponent,
    MobileComponent,
    OrganizacionesLandingComponent,
    ContactoComponent,
    FooterComponent,
    DetalleTrabajadorAprobadoComponent,
    SolicitudTrabajadorComponent,
    NuevoTrabajadorComponent,
    DetallePedidoDistComponent,
    PuntosFtComponent,
    TablaPuntosFtComponent,
    GraficaPuntosFtComponent,
    PreFacturaPedidoComponent,
    InformesDistribuidorComponent,
    TablasInformesDistribuidorComponent,
    InformesSectorComponent,
    GraficaVentasComponent,
    PedidosSugHistorialComponent,
    ChequeoPreciosDistComponent,
    CambiarCiudadComponent,
    SeleccionaPlanDistComponent,
    ActualizaPlanComponent,
    HeaderOrganizacionComponent,
    PortafolioOrganizacionComponent,
    GraficasOrganizacionComponent,
    ContenidoPortafolioComponent,
    PuntosFtOrganizacionComponent,
    PuntosftGraficasOrganizacionComponent,
    PuntosComprasOrganizacionComponent,
    InicioTopProductosComponent,
    InicioInformesDistComponent,
    TopProductoCardComponent,
    DetalleProductoOrganizacionComponent,
    SeleccionarCiudadesComponent,
    DistribuidoresOrganizacionesComponent,
    ContenidoDistribuidoresComponent,
    GrafiacasDistribuidoresOrgComponent,
    InfoAdicionalClienteComponent,
    CargarClientesExcelComponent,
    ProcesandoInfoClientesComponent,
    CuentaOrganizacionesComponent,
    TrabajadoresOrganizacionesComponent,
    InfoCuentaOrganizacionesComponent,
    HistorialPagosOrganizacionesComponent,
    SolicitudCardComponent,
    SolicitudesComponent,
    DetalleTrabajadorOrgComponent,
    ExpandirPlanOrgComponent,
    PlanesCardComponent,
    RespuestaPagoPlanComponent,
    ExpandirPuntosOrganizacionComponent,
    CargarImagenMensajesComponent,
    PortafolioDistribuidorOrgComponent,
    ChatDistribuidoresOrganizacionComponent,
    DetalleProductoDistribuidoresOrganizacionComponent,
    NoticiaCardComponent,
    RegistrarMiembroOrganizacionComponent,
    CalificacionesDistribuidorComponent,
    InformacionEncargadoPuntoComponent,
    PuntosCardComponent,
    InformesOrganizacionesComponent,
    InformesGeneralOrganizacionesComponent,
    GraficasVentasOrganizacionesComponent,
    SectorComponent,
    EstablecimientosOrganizacionesComponent,
    GraficaDetalleDistribuidorComponent,
    ChatComponent,
    ProdSugeridosAddComponent,
    ComparandoDistribuidoresComponent,
    NewCarritoComponent,
    PoliticasComponent,
    DetalleProductoFlotanteComponent,
    ModalPlanComponent,
    EditarPedidoDistComponent,
    PortafolioDistConveniosComponent,
    FinishRegisterComponent,
    ClientesNuevosComponent,
    ClientesActivosComponent,
    ClientesCreadosComponent,
    PedidosEditarComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  imports: [
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAIDSFRWmo4yNVf9hE4Yhk4C93SeBTsqwo',
      libraries: ['places', 'drawing', 'geometry'],
    }),
    NgbModule,
    NgxsModule.forRoot([TokenState]),
    NgxsStoragePluginModule.forRoot({
      key: 'auth',
    }),
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    Ng2SmartTableModule,
    NgxSliderModule,
    CommonModule,
    MatSliderModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    InfiniteScrollModule,
    // NoopAnimationsModule,
  ],
  providers: [
    //{
      //provide: HTTP_INTERCEPTORS,
      //useClass: InterceptorInterceptor,
      //multi: true
    //},
    CurrencyPipe,
    DatePipe,
    LocalDataSource,
    CookieService,
    NgbActiveModal,
    { provide: LOCALE_ID, useValue: 'es-CO' },
    
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
