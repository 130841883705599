import { Component } from '@angular/core';
import * as XLSX from 'xlsx';
import { AuthService } from '../services/auth/auth.service';
import { RestService } from '../services/rest/rest.service';
@Component({
  selector: 'app-pedidos-distribuidor',
  templateUrl: './pedidos-distribuidor.component.html',
  styleUrls: ['./pedidos-distribuidor.component.css'],
})
export class PedidosDistribuidorComponent {
  /** Variable de control para la navegación del panel */
  active = 1;
  /** Variable general para el listado de pedidos o data a imprimir */
  pedidos: any;
  /** Guarda los datos de la tabla para el excel a descargar */
  data_excel: any;
  dataHistorico: any;
  dataCurso: any;
  constructor(private rest: RestService, private auth: AuthService) {
    //this.getPedidos();
    this.getPedidosHistorico();
  }
  /**
   * Función que captura información de componente hijo del listado de pedidos
   * @param e objeto de evento que contiene data
   */
  getInfo(e: any) {
    const lista = e.data;
    for (const i in lista) {
      delete lista[i].accion;
    }
    this.pedidos = lista;
  }
  getPedidos() {
    let data_ventas: any = [];
    this.rest
      .getJWT('informes/distribuidor/tabla/ventas_reporte/' + this.auth.user_distribuidor?._id + '/curso')
      .toPromise()
      .then((ventas: any) => {
        ventas.data.forEach((venta: any) => {
          const fecha_pedido = new Date(venta.pedido_fecha);
          fecha_pedido.setMinutes(fecha_pedido.getMinutes() + fecha_pedido.getTimezoneOffset());
          let especialDescuento = 0;
          let especialAunmento = 0;
          let especialFijo = 0;
          let precio_lista = venta.detalleProducto.precios[0].precio_unidad || 0;
          let porcentaje_descuento = venta.detalleProducto.prodPorcentajeDesc || 0;
          let costoProductos = venta.detalleProducto.precios[0].precio_unidad || 0;
          let montoDescuento = 0;
          let unidadesCaja;
          let descuentos = 0;
          let data_codigos = '';
          if (venta.data_pedido.data_codigo_descuento.length > 0) {
            for (let desc of venta.data_pedido.data_codigo_descuento) {
              if (desc.valor_moneda) {
                data_codigos += desc.codigo_creado + ' / ';
                descuentos = descuentos + desc.valor_moneda;
              }
            }
          }
          if (porcentaje_descuento > 0) {
            montoDescuento = (costoProductos * porcentaje_descuento) / 100;
            montoDescuento = costoProductos - montoDescuento;
            costoProductos = montoDescuento;
          }
          if (venta.precioEspecial && venta.precioEspecial[0]) {
            switch (venta.precioEspecial[0].operacionAplicada) {
              case 'monto fijo':
                especialFijo = venta.precioEspecial[0].aplicado;
                costoProductos = venta.precioEspecial[0].precioActual;
                break;
              case 'incremento':
                especialAunmento = venta.precioEspecial[0].aplicado;
                costoProductos = venta.precioEspecial[0].precioActual;
                break;
              case 'descuento':
                especialDescuento = venta.precioEspecial[0].aplicado;
                costoProductos = venta.precioEspecial[0].precioActual;
                break;
              default:
                especialDescuento = 0;
                especialAunmento = 0;
                especialFijo = 0;
            }
          }
          unidadesCaja = venta.pedido_unidades / venta.detalleProducto.precios[0].und_x_caja;
          unidadesCaja = unidadesCaja.toFixed(2);

          const obj_ventas = {
            estadoPedido: venta.estadoPedido,
            pedido_id: venta.pedido_id,
            pedido_fecha: fecha_pedido,
            horeca_nombre: venta.horeca_nombre,
            horeca_tipo_usuario: venta.horeca_tipo_usuario,
            horeca_nit: venta.horeca_nit,
            horeca_razon_social: venta.horeca_razon_social,
            horeca_tipo_negocio: venta.horeca_tipo_negocio,
            punto_pais: venta.punto_pais,
            producto_unidades_caja: venta.producto_unidades_caja,
            punto_departamento: venta.punto_departamento,
            punto_ciudad: venta.punto_ciudad,
            punto_nombre: venta.punto_nombre,
            punto_sillas: venta.punto_sillas,
            punto_direccion: venta.punto_direccion,
            punto_domicilios: venta.punto_domicilios == true ? 'Si' : 'No',
            producto_nombre: venta.producto_nombre,
            producto_categoria: venta.producto_categoria,
            producto_linea: venta.producto_linea,
            producto_marca: venta.producto_marca,
            producto_organizacion: venta.producto_organizacion,
            producto_codigo_organizacion: venta.producto_codigo_organizacion,
            producto_codigo_distribuidor: venta.producto_codigo_distribuidor,
            producto_unidad_medida: venta.producto_unidad_medida,
            producto_cantidad_medida: venta.producto_cantidad_medida,
            precio_lista: precio_lista,
            porcentaje_descuento: porcentaje_descuento,
            precio_descuento: montoDescuento,
            pedido_cajas: unidadesCaja,
            pedido_unidades: venta.pedido_unidades,
            costoProductos: costoProductos,
            pedido_total: venta.pedido_total != undefined ? venta.pedido_total : 0,
            pedido_puntos_acumulados: venta.pedido_puntos_acumulados,
            pedido_valor_puntos: descuentos,
            equipo_comercial: venta.equipo_comercial,
            especialDescuento: especialDescuento,
            especialAunmento: especialAunmento,
            especialFijo: especialFijo,
            data_codigos: data_codigos,
          };
          data_ventas.push(obj_ventas);
        });
        this.dataCurso = data_ventas;
      })
      .catch((err) => {
        //this.mostrarMensajeError();
      });
  }
  getPedidosHistorico() {
    let data_ventas: any = [];
    this.rest
      .getJWT('informes/distribuidor/tabla/ventas_reporte/' + this.auth.user_distribuidor?._id + '/historico')
      .toPromise()
      .then((ventas: any) => {
        ventas.data.forEach((venta: any) => {
          const fecha_pedido = new Date(venta.pedido_fecha);
          fecha_pedido.setMinutes(fecha_pedido.getMinutes() + fecha_pedido.getTimezoneOffset());
          const obj_ventas = {
            pedido_id: venta.pedido_id + '',
            pedido_fecha: fecha_pedido,
            horeca_nombre: venta.horeca_nombre,
            horeca_tipo_usuario: venta.horeca_tipo_usuario,
            horeca_nit: venta.horeca_nit,
            horeca_tipo_negocio: venta.horeca_tipo_negocio,
            punto_pais: venta.punto_pais,
            punto_departamento: venta.punto_departamento,
            punto_ciudad: venta.punto_ciudad,
            punto_nombre: venta.punto_nombre,
            punto_sillas: venta.punto_sillas,
            punto_domicilios: venta.punto_domicilios == true ? 'Si' : 'No',
            producto_nombre: venta.producto_nombre,
            producto_categoria: venta.producto_categoria,
            producto_linea: venta.producto_linea,
            producto_marca: venta.producto_marca,
            producto_organizacion: venta.producto_organizacion,
            producto_codigo_organizacion: venta.producto_codigo_organizacion,
            producto_codigo_distribuidor: venta.producto_codigo_distribuidor,
            producto_unidad_medida: venta.producto_unidad_medida,
            producto_cantidad_medida: venta.producto_cantidad_medida,
            precio_lista: venta.precioActual,
            porcentaje_descuento: venta.porcentaje_descuento,
            precio_descuento: venta.precioDescuento,
            pedido_cajas: venta.pedido_cajas,
            pedido_unidades: venta.pedido_unidades,
            costoProductos: venta.costoProductos,
            pedido_total: venta.pedido_total != undefined ? venta.pedido_total : 0,
            pedido_puntos_acumulados: venta.pedido_puntos_acumulados,
            equipo_comercial: venta.equipo_comercial,
          };
          data_ventas.push(obj_ventas);
        });
        this.dataHistorico = data_ventas;
        //this.source_ventas = new LocalDataSource(this.data_ventas);
      })
      .catch((err) => {
        //this.mostrarMensajeError();
      });
  }
  /**
   * Descargue a excel listado de Usuarios
   */
  DescargaArchivo() {
    const primeraFila: any[] = [
      'ID pedido',
      'Estado del pedido',
      'Valor del pedido (COP)',
      'Establecimiento',
      'Punto de Entrega',
      'Ciudad punto',
      'Dirección punto',
      'Tipo de persona',
      'NIT/CC/CE',
      'País',
      'Departamento',
      'Ciudad',
      'Tipo de negocio',
      'Fecha de solicitud de pedido',
      'Hora de aprobación',
      'Fecha de entrega de pedido',
      'Hora de entregado',
      'Número de productos',
      'Puntos redimidos',
      'Código usado',
      'Estado de redención del código',
      'Equipo comercial asignado',
      'Método de pago',
    ];
    const object_keys = [
      'id_pedido',
      'estado_pedido',
      'valor_pedido',
      'establecimiento',
      'punto_entrega',
      'punto_ciudad',
      'punto_direccion',
      'tipo_usuario',
      'nit',
      'pais',
      'departamento',
      'ciudad',
      'tipo_negocio',
      'fecha_solicitud_pedido',
      'hora_aprobacion',
      'fecha_entrega_pedido',
      'hora_entregado',
      'numero_productos',
      'puntos',
      'codigo_usado',
      'estado_redencion',
      'equipo_comercial',
      'metodo_pago',
    ];
    /** Arma el contenido de la tabla con titulos*/
    const arreglo_final: any[] = [];
    arreglo_final.push(primeraFila);
    this.data_excel.forEach((element: any) => {
      const array = [];
      for (const column of object_keys) {
        array.push(element[column]);
      }
      arreglo_final.push(array);
    });
    this.DescargaExcel({ data: arreglo_final, nombreArchivo: `Datos consolidados pedidos.xlsx` });
  }
  /**
   * Descargue a excel listado de Usuarios
   */
  DescargaArchivoPlano(dataXls: any, titulo: any) {
    let dataPrint;
    if (dataXls === 'curso') {
      dataPrint = this.dataCurso;
    } else {
      dataPrint = this.dataHistorico;
    }
    const primeraFila: any[] = [
      'Número pedido',
      'Estado pedido',
      'Fecha pedido',
      'Nombre establecimiento',
      'Razón social',
      'Tipo persona',
      'NIT/CC/CE establecimiento',
      'Tipo negocio',
      'Punto de entrega',
      'País',
      'Departamento',
      'Ciudad',
      'Dirección punto',
      'Número de sillas',
      'Tiene domicilio',
      'Nombre producto',
      'Categoría producto',
      'Linea producto',
      'Marca producto',
      'Organización',
      'Código organización',
      'Código producto distribuidor',
      'Tamaño/cantidad',
      'Unidad de medida',
      'Presentación Und x Caja',
      'Unidades solicitadas',
      'Cajas solicitadas',
      'Precio lista Base',
      'Descuento transversal',
      //'Precio con descuento',
      'Precio especial',
      '% Descuento',
      '% Aumento',
      'Precio fijo',
      'Valor total (COP)',
      'Puntos Feat acumulados',
      'Código descuento redimido',
      'Valor de descuento redimido',
      'Vendedor distribuidor asignado',
    ];
    const object_keys = [
      'pedido_id',
      'estadoPedido',
      'pedido_fecha',
      'horeca_nombre',
      'horeca_razon_social',
      'horeca_tipo_usuario',
      'horeca_nit',
      'horeca_tipo_negocio',
      'punto_nombre',
      'punto_pais',
      'punto_departamento',
      'punto_ciudad',
      'punto_direccion',
      'punto_sillas',
      'punto_domicilios',
      'producto_nombre',
      'producto_categoria',
      'producto_linea',
      'producto_marca',
      'producto_organizacion',
      'producto_codigo_organizacion',
      'producto_codigo_distribuidor',
      'producto_cantidad_medida',
      'producto_unidad_medida',
      'producto_unidades_caja',
      'pedido_unidades',
      'pedido_cajas',
      'precio_lista',
      'porcentaje_descuento',
      //'precio_descuento',
      'costoProductos',
      'especialDescuento',
      'especialAunmento',
      'especialFijo',
      'pedido_total',
      'pedido_puntos_acumulados',
      'data_codigos',
      'pedido_valor_puntos',
      'equipo_comercial',
    ];
    /** Arma el contenido de la tabla con titulos*/
    const arreglo_final: any[] = [];
    arreglo_final.push(primeraFila);
    dataPrint.forEach((element: any) => {
      const array = [];
      for (const column of object_keys) {
        array.push(element[column]);
      }
      arreglo_final.push(array);
    });
    this.DescargaExcel({ data: arreglo_final, nombreArchivo: titulo + '.xlsx' });
  }
  /**
   * Descargue a excel un listado
   */
  DescargaExcel(obj: any) {
    /** Genera el worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(obj.data);
    /* Genera el workbook y agrega el  worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'data');
    /** Guarda el archivo */
    XLSX.writeFile(wb, obj.nombreArchivo);
  }
}
