import { Component, Input, OnInit } from '@angular/core';
import { SeleccionarPuntoEntregaComponent } from 'src/app/modal/seleccionar-punto-entrega/seleccionar-punto-entrega.component';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LocalStorageService } from '../services/local-storage/local-storage.service';
import { PuntoEntrega } from 'src/app/models/punto_entrega.model';
import { Distribuidor } from 'src/app/models/distribuidor.model';
import { faCircle, faSearch, faTimesCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { Router } from '@angular/router';
import { RestService } from 'src/app/services/rest/rest.service';
import { Producto } from 'src/app/models/producto.model';
import { ProductsService } from '../services/products/products.service';
import { DistribuidorService } from '../services/distribuidor/distribuidor.service';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { LocalDataSource } from 'ng2-smart-table';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-precios',
  templateUrl: './precios.component.html',
  styleUrls: ['./precios.component.css'],
})
export class PreciosComponent implements OnInit {
  // Todos los distribuidores
  public distribuidores: Distribuidor[] = [];
  // Punto de entrega seleccionado
  public punto_entrega?: PuntoEntrega;
  // Iconos
  public faSearch = faSearch;
  public faCircle = faCircle;
  public faTrash = faTrash;
  public faTimesCircle = faTimesCircle;
  // Guarda todos los productos que vienen de la bdd
  public productos: Producto[] = [];
  //Guarda los productos que se buscaron en la barra de autocompletado
  public productos_filtrados: Producto[] = [];
  // Guardam dinamicamente los valores de autocompletado
  public filteredOptions: any;
  public mySearch = new FormControl();
  //Placeholder para productos sin imagen
  public product_Placeholder = '../../assets/img/product-placeholder.png';
  // Modal de carga para darle feedback al usuario
  public modalCarga?: NgbModalRef;
  // Datos y configuración de la tabla
  public settings_tabla: any = {};
  public data_tabla: any = [];
  public source_tabla?: LocalDataSource;
  // Evita que al hacer click por fuera se cierre el modal
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'modal-selecionar-punto',
  };
  // Binding
  @Input() name!: string;
  @Input() data: Producto[] = [];

  constructor(
    private distribuidorService: DistribuidorService,
    private modalService: NgbModal,
    private auth: AuthService,
    public localStorage: LocalStorageService,
    private router: Router,
    private rest: RestService,
    private currencyPipe: CurrencyPipe,
    private productsService: ProductsService
  ) {}

  async ngOnInit() {
    this.punto_entrega = JSON.parse(localStorage.getItem('punto_entrega_seleccionado')!);
    if (this.punto_entrega) {
      this.auth.punto_seleccionado = this.punto_entrega;
      this.punto_entrega = this.auth.punto_seleccionado;
      await this.getProductos();
      this.autoCompletadoBusqueda();
    }
    /*this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    if (this.auth.punto_seleccionado == undefined) {
      this.selecionarPunto();
    } else {
      this.punto_entrega = this.auth.punto_seleccionado;
      await this.getProductos();
      this.autoCompletadoBusqueda();
    }*/
    this.configSmartTable();
  }

  /**
   * Recupera todos los productos de los distribuidores que están vinculados al punto de entrega
   */
  getProductos() {
    this.productos = [];
    this.rest
      .getJWT('chequeo_precios/' + this.punto_entrega?._id)
      .toPromise()
      .then((resp: any) => {
        console.log('chequeo', resp);
        resp.data.forEach((element: any) => {
          this.productos = this.productos.concat(element.data_productos);
        });
        // Elimina objetos repetidos basado en cod feat para que no se repitan en la barra de busqueda
        this.productos = this.productos.filter(
          (value: any, index: any, self: any) => index === self.findIndex((t: any) => t.codigo_ft == value.codigo_ft)
        );
        this.modalCarga?.close();
      })
      .catch((err) => {
        console.log(err);
        this.modalCarga?.close();
        const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.close_callback = () => {
          this.router.navigate(['/inicio']);
        };
      });
  }

  /**
   * Configura los titulos y demas settings de la tabla
   * para recibir información de la base de datos
   */
  private configSmartTable(): void {
    this.settings_tabla = {
      pager: {
        display: true,
        perPage: 15,
      },
      actions: {
        columnTitle: 'Acción',
        add: false,
        edit: false,
        delete: false,
      },
      hideSubHeader: false,
      columns: {
        accion: {
          title: 'Acción',
          editable: false,
          filter: false,
          type: 'custom',
          renderComponent: BtnEditarPedidoDist,
        },
        distribuidor: {
          title: 'Distribuidor',
          editable: false,
          filter: true,
        },
        precio_und: {
          title: 'Precio Und. (COP)',
          editable: false,
          filter: true,
        },
        precio_und_med: {
          title: 'Precio Unidad Medida (COP)',
          editable: false,
          filter: true,
        },
        und_x_caja: {
          title: 'Und. x Caja ',
          editable: false,
          filter: true,
        },
        precio_caja: {
          title: 'Precio Caja (COP)',
          editable: false,
          filter: true,
        },
      },
    };
  }

  /**
   * Este metodo recupera solo los productos con el mismo cod. feat del seleccionado en la busqueda
   * @param event Datos del elemento seleccioando en la lista desplegable de la barra de busqueda
   */
  public getProductosFiltrados(event: any) {
    // Reset de la tabla
    this.data_tabla = [];
    this.source_tabla = new LocalDataSource(this.data_tabla);
    this.productos_filtrados = [];
    // Carga nuevos datos
    this.rest
      .getJWT('chequeo_precios_codFeat/' + this.punto_entrega?._id + '/' + event.option.value.codigo_ft)
      .toPromise()
      .then((resp: any) => {
        resp.data.forEach((element: any) => {
          this.productos_filtrados = this.productos_filtrados.concat(element.data_productos);
        });
        this.modalCarga?.close();
        this.cargarDatosTablaDistribuidores();
      })
      .catch((err) => {
        console.log(err);
        this.modalCarga?.close();
        const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.close_callback = () => {
          this.router.navigate(['/inicio']);
        };
      });
  }

  /**
   * Se cargan los datos recuperados de la base de datos en la tabla
   */
  private cargarDatosTablaDistribuidores(): void {
    this.productos_filtrados.forEach((producto: any) => {
      let precio_und_med = 0;
      if (producto.precios[0].cantidad_medida != 0 && producto.precios[0].cantidad_medida != undefined) {
        precio_und_med =
          Math.round((producto.precios[0].precio_unidad / producto.precios[0].cantidad_medida) * 100) / 100;
      }
      const obj_pedido_distribuidores = {
        distribuidor: producto.data_distribuidor.length != 0 ? producto.data_distribuidor[0].nombre : '-',
        precio_und: producto.precios[0].precio_unidad != undefined ? producto.precios[0].precio_unidad : 0,
        precio_und_med: precio_und_med,
        precio_caja: producto.precios[0].precio_caja != undefined ? producto.precios[0].precio_caja : 0,
        und_x_caja: producto.precios[0].und_x_caja != undefined ? producto.precios[0].und_x_caja : 0,
        accion: {
          id_producto: producto._id,
          _idDistribuidor: producto.data_distribuidor.length != 0 ? producto.data_distribuidor[0]._id : '-',
        },
      };
      this.data_tabla.push(obj_pedido_distribuidores);
    });
    this.source_tabla = new LocalDataSource(this.data_tabla);
  }

  /**
   * Lanza un modal para seleccionar el punto de entrega sobre el que se
   * van a gestionar las solicitudes
   */
  public selecionarPunto() {
    const modalRef = this.modalService.open(SeleccionarPuntoEntregaComponent, this.ngbModalOptions);
    modalRef.componentInstance.callback = () => {
      this.punto_entrega = this.auth.punto_seleccionado;
      this.localStorage.setItem<any>('punto_entrega_seleccionado', this.punto_entrega);
      this.getProductos();
      this.autoCompletadoBusqueda();
    };
  }

  /**-----AUTOCOMPLETADO Y LLENADO DE TALBA----- */

  /*Este metodo tiene como objeto autocompletar la busqueda del usuario */
  public autoCompletadoBusqueda() {
    this.filteredOptions = this.mySearch.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.nombre)),
      map((nombre) => (nombre ? this._filter(nombre) : this.productos.slice()))
    );
  }

  public displayFn(user: any): string | undefined {
    return user ? user.nombre : undefined;
  }
  public returnFn(user: any): string | undefined {
    return user ? user.value : undefined;
  }
  public itemDisplayFn(item: any) {
    return item ? item.name : '';
  }

  private _filter(nombre: string) {
    const filterValue = this.normalizeString(nombre.toLowerCase());
    return this.productos.filter((option: any) =>
      this.normalizeString(option.nombre).toLowerCase().includes(filterValue)
    );
  }
  private _filter2(nombre: string) {
    const filterValue = this.normalizeString(nombre.toLowerCase());
    return this.productos.filter(
      (option: any) => this.normalizeString(option.nombre).toLowerCase().indexOf(filterValue) === 0
    );
  }

  /**
   * Toma el string que entra por parámetro y cambia tildes y diéresis
   * por las letras sin acento, y lo pasa a minúsculas
   * @param pWord El string a filtrar
   * @returns El string filtrado
   */
  normalizeString(pWord: string): string {
    return pWord
      .trim()
      .normalize('NFD')
      .replace(/\p{Diacritic}/gu, '')
      .toLowerCase();
  }
}

@Component({
  selector: 'app-btn-editar-pedido-dist',
  template: `
    <button class="btn-purple" (click)="openDetallePedido()">Ver</button>
  `,
  styles: [
    `
      .btn-purple {
        width: 100%;
        padding: 0px;
        background-color: transparent;
        border: transparent;
        color: #8e6ff7;
      }
    `,
  ],
})
export class BtnEditarPedidoDist {
  /** Modal de carga para darle feedback al usuario */
  public modalCarga?: NgbModalRef;
  /** Evita que al hacer click por fuera se cierre el modal */
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'modal-selecionar-punto',
  };
  /** Datos del producto a editar */
  @Input() value?: { _idDistribuidor: string; id_producto: string };

  constructor(private router: Router, private rest: RestService, private modalService: NgbModal) {}

  /**
   * Toma el id que entró por parámetro a este componente y abre el componente
   * del detalle de pedido de distribuidor usando el id como parámetro de la ruta
   */
  public openDetallePedido() {
    const punto_entrega = JSON.parse(localStorage.getItem('punto_entrega_seleccionado') || '');
    this.rest
      .getJWT('distribuidores_vinculados_dist_punto/' + this.value?._idDistribuidor + '/' + punto_entrega._id)
      .toPromise()
      .then((resp: any) => {
        if (resp.data[0].cartera == false && resp.data[0].pazysalvo == true) {
          if (
            this.value?._idDistribuidor !== '' ||
            this.value?._idDistribuidor !== undefined ||
            this.value?.id_producto !== '' ||
            this.value?.id_producto !== undefined
          ) {
            this.router.navigate([`distribuidores/${this.value?._idDistribuidor}/producto/${this.value?.id_producto}`]);
          }
        } else {
          const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
          modalRef.componentInstance.title = '¡Oh oh!';
          modalRef.componentInstance.msg =
            'Recuerda que no puedes realizar pedidos porque tienes pagos pendientes con este distribuidor. Comunícate con el distribuidor para que puedas pedir este producto';
          modalRef.componentInstance.btn_msg = 'Volver';
        }
      })
      .catch((err) => {});
  }
}
