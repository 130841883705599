import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LocalDataSource } from 'ng2-smart-table';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { PedidosDistribuidorComponent } from '../pedidos-distribuidor.component';
import { DistribuidorService } from 'src/app/services/distribuidor/distribuidor.service';
import {
  faChevronLeft,
  faChevronRight,
  faMapMarkerAlt,
  faSearch,
  faStoreAlt,
  faTimes,
  faTimesCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-pedidos-info',
  templateUrl: './pedidos-info.component.html',
  styleUrls: ['./pedidos-info.component.css'],
})
export class PedidosInfoComponent implements OnInit {
  activeNav1: number = 1;
  activeNav2: number = 1;
  buscadorActivo: boolean = false;
  id_trabajador: string = '';
  /** Referencias a íconos FontAwesome para la UI */
faSearch = faSearch;
faTimes = faTimes;
faTimesCircle = faTimesCircle;
faChevronRight = faChevronRight;
faChevronLeft = faChevronLeft;
faChevronRigth = faChevronRight;
faStoreAlt = faStoreAlt;
faMapMarkerAlt = faMapMarkerAlt;
faTrash = faTrash;
  /**Variable para envío de la lista de pedidos a componente padre - función imprimir a xlxs */
  @Output() setListaInfo = new EventEmitter<any>();
  /** Variable de control para la navegación del panel */
  active = 1;
  /** Datos y configuración de la tabla */
  public settings: any = {
    add: {
      addButtonContent: '', // Elimina el texto de "Add new"
      createButtonContent: '',
      cancelButtonContent: '',
      confirmCreate: false,
    },
    actions: {
      columnTitle: '',
      add: false, // Deshabilita completamente el botón de agregar
      edit: false,
      delete: false,
      custom: [],
    },
    noDataMessage: '',
  };
  public data: any = [];
  public source?: LocalDataSource;
  public todos_pedidos: any[] = [];
  /** Guarda los datos de los puntos vinculados */
  public clientes_vinculados_distribuidor: any;
  /** Indicadores de los pedidos */
  pendientes = 0;
  aprobados = 0;
  alistando = 0;
  despachados = 0;
  entregados = 0;
  cancelados = 0;
  /** Referencia al modal de carga */
  modalCarga?: NgbModalRef;
  loading: boolean | undefined;
  indicadores: any = [];
  currentPage = 1; // Página actual
  totalPaginas: number = 1;
  totalClientes: any;
  estados_historico: any[] = [];
  estados_curso: any[] = [];
  estado_actual = 'curso';
  estado_pedido_actual: any;
  buscador: string  = '';
  lista_trabajadores: any;
  dataHistorico: any;
  dataCurso: any;
  loadingXLS: boolean = false;
  constructor(
    private rest: RestService,
    private auth: AuthService,
    private clientService: ClientsService,
    private currency: CurrencyPipe,
    private modalService: NgbModal,
    private distribuidorService: DistribuidorService,
    private pedidosDistribuidorComponent: PedidosDistribuidorComponent,
    private router: Router,
    private datePipe: DatePipe
  ) {}
  async ngOnInit() {
    this.clientes_vinculados_distribuidor = await this.clientService
      .getClienteByDistribuidor(this.auth.user_distribuidor?._id)
      .toPromise();
    this.configSmartTable();
   // this.getPedidos();
    this.filtrarPorEstado('curso', 1, null);
    this.getIndicadoresPedidosDist();
    this.getAllWorkers();

  }
  get pageNumbers(): number[] {
    const start = Math.floor((this.currentPage - 1) / 10) * 10 + 1;
    const end = Math.min(start + 9, this.totalPaginas);
    const pages = [];
    
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
  
    return pages;
  }
  capturarTexto() {
      if(this.buscador.length === 0){
          this.limpiarFiltros();
      }
  }
  buscarNombre(){
    this.totalClientes = 0
    this.totalPaginas = 0
    this.loading = true;

    this.buscadorActivo = true;
    let busqueda
    let trabajador
    if(this.buscador && this.buscador !== ''){
      busqueda = this.buscador
    }
    if(this.id_trabajador && this.id_trabajador !== ''){
      trabajador = this.id_trabajador
    }
    this.distribuidorService.getDistribuidoresPedidosBuscador(busqueda, trabajador,'1',this.auth.user_distribuidor?._id).subscribe((data) => {
      this.source = new LocalDataSource(data.data);
     this.loading = false;
     console.log('')
     console.log('data buscador',data )
     if(data.data.length > 0){
      this.totalPaginas = data.totalPaginas;  
      this.totalClientes = data.totalPaginas*10;
     }

    });


  }
  limpiarFiltros(){
    this.buscadorActivo = false;
    this.buscador = '';
    this.id_trabajador='';
    let estado = null
    if(this.estado_pedido_actual){
      estado = this.estado_pedido_actual
    }
    this.activeNav2 = 1
    this.filtrarPorEstado(this.estado_actual, 1, null)

  }
  goToPage(page: any): void {
    if (parseInt(page) < 1 || parseInt(page) > this.totalPaginas) return;
    this.currentPage = page;
    this.filtrarPorEstado(this.estado_actual, page, this.estado_pedido_actual || null)
  }
  /**
   * Get para obtener todos los trabajadores
   */
  getAllWorkers(){
    this.loading = true;
    this.distribuidorService.getAllTrabajadors().subscribe((data) => {
      this.lista_trabajadores= data.data
      this.loading = false;
    }); 
    
  }
  /**
   * Get para obtener pedidos bajo indicadores
   */
  getIndicadoresPedidosDist(){
    this.loading = true;
    this.distribuidorService.getDistribuidoresPedidosIndicadoresNew(this.auth.user_distribuidor?._id).subscribe((data) => {
      this.indicadores = data.indicadores;
      this.estados_historico = data.estados.historico || [];
      this.estados_curso = data.estados.curso || [];
      this.loading = false;
    }); 
    
  }
  get elementos() {
    return this.estado_actual === 'curso' ? this.estados_curso : this.estados_historico; // Corregido el retorno
  }
  /**
   * Recupera todos los pedidos del usuario
   */
  getPedidos() {
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, ngbModalOptions);
    this.rest
      .getJWT(`pedidos/detalle_pedidos_distribuidor/${this.auth.user_distribuidor?._id}`)
      .toPromise()
      .then((resp: any) => {
        this.todos_pedidos = resp;
        //this.data =resp;
        this.loadData();
        this.modalCarga?.close();
      })
      .catch((err) => {
        this.modalCarga?.close();
        const modalRef = this.modalService.open(SimpleComponent, {
          centered: true,
          size: 'md',
        });
        if (err.status && err.status == 401) {
          this.auth.signOut();
          modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
          modalRef.componentInstance.title = '¡Oh oh!';
          modalRef.componentInstance.msg = 'Tu sesión ha expirado. Por favor inicia sesión de nuevo para continuar.';
          modalRef.componentInstance.btn_msg = 'Listo';
          modalRef.componentInstance.close_callback = () => {
            this.router.navigate(['/login']);
          };
          return;
        }
        this.modalCarga?.close();
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg =
          'Ocurrió un error cargando la información de los pedidos. Intenta de nuevo más tarde.';
        modalRef.componentInstance.btn_msg = 'Listo';
        modalRef.componentInstance.close_callback = () => {
          this.router.navigateByUrl('/inicio-distribuidor');
        };
      });
  }

  /**
   * Configura la tabla
   */
  /*
  private configSmartTable(): void {
    this.settings = {
      pager: {
        display: true,
        perPage: 10,
        showFirstLast: false,
        showPrevNext: false,
        align: 'center',
      },
      actions: {
        columnTitle: 'Acción',
        delete: false,
        edit: false,
        add: false,
        custom: [
          {
            name: 'ver',
            title: 'Ver',
          },
        ],
      },
      edit: {
        editButtonContent: 'Editar',
        saveButtonContent: 'Guardar',
        cancelButtonContent: 'Cancelar',
      },
      noDataMessage: 'NO TIENES CLIENTES O AÚN NO TIENES CLIENTES VINCULADOS',
      columns: {
        id_pedido: {
          title: 'ID pedido',
          filter: false,
        },
        estadoPedido: {
          title: 'Estado del pedido',
          filter: false,
        },
        valorPedido: {
          title: 'Valor del pedido (COP)',
          filter: false,
        },
        metodo_pago: {
          title: 'Método de pago',
          filter: false,
        },
        establecimiento: {
          title: 'Establecimiento',
          filter: false,
        },
        punto: {
          title: 'Punto de Entrega',
          filter: false,
        },
        tipo_persona: {
          title: 'Tipo de persona',
          filter: false,
        },
        nit: {
          title: 'NIT/CC/CE',
          filter: false,
        },
        pais: {
          title: 'País',
          filter: false,
        },
        departamento: {
          title: 'Departamento',
          filter: false,
        },
        ciudad: {
          title: 'Ciudad',
          filter: false,
        },
        tipo_negocio: {
          title: 'Tipo de negocio',
          filter: false,
        },
        fecha_pedido: {
          title: 'Fecha de solicitud de pedido',
          filter: false,
          valuePrepareFunction: (value: any) => value || 'N/A',
        },
        fecha_apro: {
          title: 'Fecha y hora de aprobación',
          filter: false,
          },
          fecha_entr: {
          title: 'Fecha y hora de entrega',
          filter: false,
          },
          cant_productos: {
          title: 'Número de productos',
          filter: false,
        },
        codigos_usados: {
          title: 'Código usado',
          filter: false,
        },
        estados_codigos_usados: {
          title: 'Estado de redención del código',
          filter: false,
        },
        equipo_comercial: {
          title: 'Equipo comercial asignado',
          filter: false,
        },
      },
    };
  }
  */
  /**
   * Configura la tabla
   */
  private configSmartTable(): void {
    this.settings = {
      add: {
        addButtonContent: '', // Elimina el texto de "Add new"
        createButtonContent: '',
        cancelButtonContent: '',
        confirmCreate: false,
      },
      pager: {
        perPage: 10,
      },
      actions: {
        columnTitle: 'Acción',
        add: false, // Deshabilita completamente el botón de agregar
        edit: false,
        delete: false,
        custom: [
          {
            name: 'ver',
            title: 'Ver',
          },
        ],
      },
      noDataMessage: 'AÚN NO TIENES CLIENTES VINCULADOS',
      hideSubHeader: false,
      columns: {
        id_pedido: {
          title: 'ID pedido',
          filter: false,
        },
        estadoPedido: {
          title: 'Estado del pedido',
          filter: false,
        },
        valorPedido: {
          title: 'Valor del pedido (COP)',
          filter: false,
        },
        metodo_pago: {
          title: 'Método de pago',
          filter: false,
        },
        establecimiento: {
          title: 'Establecimiento',
          filter: false,
        },
        punto: {
          title: 'Punto de Entrega',
          filter: false,
        },
        tipo_persona: {
          title: 'Tipo de persona',
          filter: false,
        },
        nit: {
          title: 'NIT/CC/CE',
          filter: false,
        },
        pais: {
          title: 'País',
          filter: false,
        },
        departamento: {
          title: 'Departamento',
          filter: false,
        },
        ciudad: {
          title: 'Ciudad',
          filter: false,
        },
        tipo_negocio: {
          title: 'Tipo de negocio',
          filter: false,
        },
        fecha_pedido: {
          title: 'Fecha de solicitud de pedido',
          filter: false,
          valuePrepareFunction: (value: string) => {
            const date = new Date(value);
            return isNaN(date.getTime()) ? 'N/A' : date.toISOString().split('T')[0];
          },
        },
        fecha_apro: {
          title: 'Fecha y hora de aprobación',
          filter: false,
          valuePrepareFunction: (value: string) => {
            const date = new Date(value);
            return isNaN(date.getTime()) ? 'N/A' : date.toTimeString().split(' ')[0];
          },
        },
        fecha_entr: {
          title: 'Fecha y hora de entrega',
          filter: false,
          valuePrepareFunction: (value: string) => {
            const date = new Date(value);
            return isNaN(date.getTime()) ? 'N/A' : date.toTimeString().split(' ')[0];
          },
        },
        cant_productos: {
          title: 'Número de productos',
          filter: false,
        },
        codigos_usados: {
          title: 'Código usado',
          filter: false,
        },
        estados_codigos_usados: {
          title: 'Estado de redención del código',
          filter: false,
        },
        equipo_comercial: {
          title: 'Equipo comercial asignado',
          filter: false,
        },
      },
    };
    
  }
  /**
   * Carga los datos a mostrar en la tabla
   */
  async loadData() {

    /** Carga la información en la tabla */
    let resp_cods = [];
    let cods_str = '';
    let cods_estados = '';
    for (const aux of this.todos_pedidos) {
      //Información de los códigos usados en el pedido
      resp_cods = this.processCodes(aux);
      cods_str = resp_cods[0];
      cods_estados = resp_cods[1];
      //Información del estado del pedido
      await this.processState(aux, cods_str, cods_estados);
    }
    //this.filtrarPorEstado('todos','0')
    this.source = new LocalDataSource(this.data);
  }

  /**
   * Hace un check para verificar que el pedido se puede tener en cuenta
   * en la tabla según su estado (se excluye únicamente si tiene estado
   * 'Pendiente' o 'Sugerido'). Si sí cumple, se agrega al arreglo de data
   * y se actualiza el source de la tabla
   */
  async processState(pedido: any, cods_str: string, cods_estados: string) {
    /** Arma los nombres del equipo comercial */
    let nombres_equipo_comercial = '';
    if( pedido.equipo_comercial[0]){
      pedido.equipo_comercial[0].forEach((trabajador: any) => {
        nombres_equipo_comercial =
          nombres_equipo_comercial + ' ' + trabajador.nombres + ' ' + trabajador.apellidos + '  | ';
      });
      nombres_equipo_comercial = nombres_equipo_comercial.slice(0, -2);
    }else{
      nombres_equipo_comercial = pedido.equipo_comercial
    }

    /** Se arma objeto para la tabla */
    const obj_pedido = {
      accion: {
        id_pedido: pedido._id,
      },
      id_pedido: pedido.idPedido,
      estado_pedido: this.procesarEstado(pedido.estado),
      valor_pedido: pedido.valor_pedido,
      establecimiento: pedido.establecimiento[0] || '',
      punto_entrega: pedido.punto_entrega[0] || '',
      tipo_usuario: pedido.tipo_usuario[0] || '',
      nit: pedido.nit[0] || '',
      pais: pedido.pais[0] || '',
      departamento: pedido.departamento[0] || '',
      ciudad: pedido.ciudad[0] || '',
      tipo_negocio: pedido.tipo_negocio[0] || '',
      fecha_solicitud_pedido: pedido.fecha?pedido.fecha.split('T')[0] : 'No registra',
      hora_aprobacion: pedido.tracking_aprobado_externo || '',
      fecha_entrega_pedido: pedido.tracking_entregado?pedido.tracking_entregado[1].split('T')[0] : '',
      hora_entregado: pedido.tracking_entregado? pedido.tracking_entregado[0] : '',
      numero_productos: pedido.numero_productos || '0',
      puntos: pedido.puntos_redimidos || '0',
      codigo_usado: cods_str,
      estado_redencion: cods_estados,
      equipo_comercial: nombres_equipo_comercial,
      metodo_pago: pedido.metodo_pago,
    };
    if (pedido.estado != 'Sugerido') {
      this.data.push(obj_pedido);
      this.pedidosDistribuidorComponent.data_excel = this.data;
     // this.generarEvento();
    }
  }

  /**
   * Permite procesar los códigos de descuento de un pedido
   * y sus estados. Devuelve un arreglo donde la primera
   * posición tiene un string con los códigos usados y la
   * segunda posición tiene un string con los estados
   * correspondientes a los códigos usados
   */
  processCodes(aux: any) {
    //Información de los códigos de descuento
    let cods_str = '';
    let cods_estados = '';
    let i = 0;
    if(aux.codigo_descuento){
      for (const cod_aux of aux.codigo_descuento) {
        cods_str += cod_aux.codigo_creado;
        cods_estados += cod_aux.estado;
        if (i != aux.codigo_descuento.length - 1) {
          cods_str += ', ';
          cods_estados += ', ';
        }
        i++;
      }
      if (cods_str == '') {
        cods_str = 'No registra';
      }
      if (cods_estados == '') {
        cods_estados = 'No registra';
      }
    }else{
      cods_str = 'No registra';
      cods_estados = 'No registra';
    }
    



    return [cods_str, cods_estados];
  }

  /** Función que genera el evento para paso de información del listado de pedidos a componente padre */
  generarEvento() {
    this.setListaInfo.emit(JSON.parse(JSON.stringify(this.source)));
  }

  /**
   * Toma el estado actual del pedido en su forma cruda
   * y asigna un equivalente que tenga sentido para el
   * distribuidor
   * @param estado_crudo El estado a traducir
   * @return Un string con el estado equivalente para el distribuidor
   */
  procesarEstado(estado_crudo: string): string {
    let estado_traducido = estado_crudo;

    switch (estado_crudo) {
      case 'Pendiente':
        estado_traducido = 'Pendiente';
        this.pendientes++;
        break;
      case 'Sugerido':
        estado_traducido = 'Sugerido';
        this.pendientes++;
        break;
      case 'Aprobado Interno':
        estado_traducido = 'Pendiente';
        this.pendientes++;
        break;
      case 'Aprobado Externo':
        estado_traducido = 'Aprobado';
        this.aprobados++;
        break;
      case 'Alistamiento':
        estado_traducido = 'Alistamiento';
        this.alistando++;
        break;
      case 'Despachado':
        estado_traducido = 'Despachado';
        this.despachados++;
        break;
      case 'Facturado':
        estado_traducido = 'Facturado';
        this.entregados++;
        break;
      case 'Entregado':
        estado_traducido = 'Entregado';
        this.entregados++;
        break;
      case 'Recibido':
        estado_traducido = 'Recibido';
        this.entregados++;
        break;
      case 'Calificado':
        estado_traducido = 'Calificado';
        this.entregados++;
        break;
      case 'Cancelado por horeca':
        estado_traducido = 'Cancelado por cliente';
        this.cancelados++;
        break;
      case 'Cancelado por distribuidor':
        estado_traducido = 'Cancelado por distribuidor';
        this.cancelados++;
        break;
      case 'Rechazado':
        estado_traducido = 'Rechazado';
        this.cancelados++;
        break;
    }
    return estado_traducido;
  }

  filtrarPorEstado(filtro: string, page: any, estado: any) {
    if(filtro){
      this.estado_actual = filtro
    }
    if(estado && estado != 1){
      this.estado_pedido_actual = estado
    }else{
      this.activeNav2 = 1;
    }
    this.loading = true;
    /** Se limpia la tabla para volver a cargar información nueva */
    //this.source = new LocalDataSource(this.data);
    this.distribuidorService.getDistribuidoresPedidosEstadoNew(filtro,page,estado,this.auth.user_distribuidor?._id, null, null).subscribe((data) => {
      this.source = new LocalDataSource(data.data);
     this.loading = false;
     this.totalPaginas = data.totalPaginas;  
     this.totalClientes = data.totalPaginas*10;

    });
    /** Filtra los pedidos según selección en navpills */
    /*if (filtro) {
      if(filtro === 'todos'){
        this.loading = false;
        this.source = new LocalDataSource(this.data);
      }else{

        let filter: any[] = [];
        const estados = {
          pendientes: ['Aprobado Interno', 'Pendiente'],
          aprobados: ['Aprobado Externo', 'Aprobado'],
          alistando: ['Alistamiento'],
          despachados: ['Despachado'],
          entregados: ['Entregado', 'Recibido', 'Facturado', 'Calificado'],
          cancelados: ['Cancelado por cliente', 'Cancelado por distribuidor', 'Rechazado'], //No habilitado
        };
        if (filtro === 'pendientes') {
          filter = estados.pendientes;
        } else if (filtro === 'aprobados') {
          filter = estados.aprobados;
        } else if (filtro === 'alistando') {
          filter = estados.alistando;
        } else if (filtro === 'despachados') {
          filter = estados.despachados;
        } else if (filtro === 'entregados') {
          filter = estados.entregados;
        } else if (filtro === 'cancelados') {
          filter = estados.cancelados;
        }
        const pedidos_filtrados = this.data.filter((el: any) => {
          return filter.some((f: string) => {
            return el.estado_pedido === f;
          });
        });
        this.loading = false;
        this.source = new LocalDataSource(pedidos_filtrados);
      }
    } else {
      this.loading = false;
      this.source = new LocalDataSource(this.data);
    }*/
  }
  onCustom(event: any) {
    this.router.navigate([`/pedidos-distribuidor/${event.data._id}`]);
  }
  public descargarpedidos(estado:any){
    if(estado === 'curso'){
      this.getPedidosCurso()
    }else{
      this.getPedidosHistorico()
    }
  }
  public prepararArchivoPlano(dataXls: any, titulo: any){
    let dataPrint;
    if (dataXls === 'curso') {
      dataPrint = this.dataCurso;
    } else {
      dataPrint = this.dataHistorico;
    }
    const primeraFila: any[] = [
      'Número pedido',
      'Estado pedido',
      'Fecha pedido',
      'Nombre establecimiento',
      'Razón social',
      'Tipo persona',
      'NIT/CC/CE establecimiento',
      'Tipo negocio',
      'Punto de entrega',
      'País',
      'Departamento',
      'Ciudad',
      'Dirección punto',
      'Número de sillas',
      'Tiene domicilio',
      'Nombre producto',
      'Categoría producto',
      'Linea producto',
      'Marca producto',
      'Organización',
      'Código organización',
      'Código producto distribuidor',
      'Tamaño/cantidad',
      'Unidad de medida',
      'Presentación Und x Caja',
      'Unidades solicitadas',
      'Cajas solicitadas',
      'Precio lista Base',
      'Descuento transversal',
      //'Precio con descuento',
      'Precio especial',
      '% Descuento',
      '% Aumento',
      'Precio fijo',
      'Valor total (COP)',
      'Puntos Feat acumulados',
      'Código descuento redimido',
      'Valor de descuento redimido',
      'Vendedor distribuidor asignado',
    ];
    const object_keys = [
      'pedido_id',
      'estadoPedido',
      'pedido_fecha',
      'horeca_nombre',
      'horeca_razon_social',
      'horeca_tipo_usuario',
      'horeca_nit',
      'horeca_tipo_negocio',
      'punto_nombre',
      'punto_pais',
      'punto_departamento',
      'punto_ciudad',
      'punto_direccion',
      'punto_sillas',
      'punto_domicilios',
      'producto_nombre',
      'producto_categoria',
      'producto_linea',
      'producto_marca',
      'producto_organizacion',
      'producto_codigo_organizacion',
      'producto_codigo_distribuidor',
      'producto_cantidad_medida',
      'producto_unidad_medida',
      'producto_unidades_caja',
      'pedido_unidades',
      'pedido_cajas',
      'precio_lista',
      'porcentaje_descuento',
      //'precio_descuento',
      'costoProductos',
      'especialDescuento',
      'especialAunmento',
      'especialFijo',
      'pedido_total',
      'pedido_puntos_acumulados',
      'data_codigos',
      'pedido_valor_puntos',
      'equipo_comercial',
    ];
    /** Arma el contenido de la tabla con titulos*/
    const arreglo_final: any[] = [];
    arreglo_final.push(primeraFila);
    dataPrint.forEach((element: any) => {
      const array = [];
      for (const column of object_keys) {
        array.push(element[column]);
      }
      arreglo_final.push(array);
    });
    this.DescargaExcel({ data: arreglo_final, nombreArchivo: titulo + '.xlsx' });
  }
    /**
   * Descargue a excel un listado
   */
    DescargaExcel(obj: any) {
      this.loadingXLS = false;
      /** Genera el worksheet */
      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(obj.data);
      /* Genera el workbook y agrega el  worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'data');
      /** Guarda el archivo */
      XLSX.writeFile(wb, obj.nombreArchivo);
    }
    getPedidosCurso() {
      this.loadingXLS = true;
      let data_ventas: any = [];
      this.rest
        .getJWT('informes/distribuidor/tabla/ventas_reporte/' + this.auth.user_distribuidor?._id + '/curso')
        .toPromise()
        .then((ventas: any) => {
          ventas.data.forEach((venta: any) => {
            const fecha_pedido = new Date(venta.pedido_fecha);
            fecha_pedido.setMinutes(fecha_pedido.getMinutes() + fecha_pedido.getTimezoneOffset());
            let especialDescuento = 0;
            let especialAunmento = 0;
            let especialFijo = 0;
            let precio_lista = venta.detalleProducto.precios[0].precio_unidad || 0;
            let porcentaje_descuento = venta.detalleProducto.prodPorcentajeDesc || 0;
            let costoProductos = venta.detalleProducto.precios[0].precio_unidad || 0;
            let montoDescuento = 0;
            let unidadesCaja;
            let descuentos = 0;
            let data_codigos = '';
            if (venta.data_pedido.data_codigo_descuento.length > 0) {
              for (let desc of venta.data_pedido.data_codigo_descuento) {
                if (desc.valor_moneda) {
                  data_codigos += desc.codigo_creado + ' / ';
                  descuentos = descuentos + desc.valor_moneda;
                }
              }
            }
            if (porcentaje_descuento > 0) {
              montoDescuento = (costoProductos * porcentaje_descuento) / 100;
              montoDescuento = costoProductos - montoDescuento;
              costoProductos = montoDescuento;
            }
            if (venta.precioEspecial && venta.precioEspecial[0]) {
              switch (venta.precioEspecial[0].operacionAplicada) {
                case 'monto fijo':
                  especialFijo = venta.precioEspecial[0].aplicado;
                  costoProductos = venta.precioEspecial[0].precioActual;
                  break;
                case 'incremento':
                  especialAunmento = venta.precioEspecial[0].aplicado;
                  costoProductos = venta.precioEspecial[0].precioActual;
                  break;
                case 'descuento':
                  especialDescuento = venta.precioEspecial[0].aplicado;
                  costoProductos = venta.precioEspecial[0].precioActual;
                  break;
                default:
                  especialDescuento = 0;
                  especialAunmento = 0;
                  especialFijo = 0;
              }
            }
            unidadesCaja = venta.pedido_unidades / venta.detalleProducto.precios[0].und_x_caja;
            unidadesCaja = unidadesCaja.toFixed(2);
  
            const obj_ventas = {
              estadoPedido: venta.estadoPedido,
              pedido_id: venta.pedido_id,
              pedido_fecha: fecha_pedido,
              horeca_nombre: venta.horeca_nombre,
              horeca_tipo_usuario: venta.horeca_tipo_usuario,
              horeca_nit: venta.horeca_nit,
              horeca_razon_social: venta.horeca_razon_social,
              horeca_tipo_negocio: venta.horeca_tipo_negocio,
              punto_pais: venta.punto_pais,
              producto_unidades_caja: venta.producto_unidades_caja,
              punto_departamento: venta.punto_departamento,
              punto_ciudad: venta.punto_ciudad,
              punto_nombre: venta.punto_nombre,
              punto_sillas: venta.punto_sillas,
              punto_direccion: venta.punto_direccion,
              punto_domicilios: venta.punto_domicilios == true ? 'Si' : 'No',
              producto_nombre: venta.producto_nombre,
              producto_categoria: venta.producto_categoria,
              producto_linea: venta.producto_linea,
              producto_marca: venta.producto_marca,
              producto_organizacion: venta.producto_organizacion,
              producto_codigo_organizacion: venta.producto_codigo_organizacion,
              producto_codigo_distribuidor: venta.producto_codigo_distribuidor,
              producto_unidad_medida: venta.producto_unidad_medida,
              producto_cantidad_medida: venta.producto_cantidad_medida,
              precio_lista: precio_lista,
              porcentaje_descuento: porcentaje_descuento,
              precio_descuento: montoDescuento,
              pedido_cajas: unidadesCaja,
              pedido_unidades: venta.pedido_unidades,
              costoProductos: costoProductos,
              pedido_total: venta.pedido_total != undefined ? venta.pedido_total : 0,
              pedido_puntos_acumulados: venta.pedido_puntos_acumulados,
              pedido_valor_puntos: descuentos,
              equipo_comercial: venta.equipo_comercial,
              especialDescuento: especialDescuento,
              especialAunmento: especialAunmento,
              especialFijo: especialFijo,
              data_codigos: data_codigos,
            };
            data_ventas.push(obj_ventas);
          });
          this.dataCurso = data_ventas;
          this.prepararArchivoPlano('curso', 'pedidos_en_curso');
        })
        .catch((err) => {
          this.loadingXLS = false;

          //this.mostrarMensajeError();
        });
    }
    getPedidosHistorico() {
      let data_ventas: any = [];
      this.loadingXLS = true;
      this.rest
        .getJWT('informes/distribuidor/tabla/ventas_reporte/' + this.auth.user_distribuidor?._id + '/historico')
        .toPromise()
        .then((ventas: any) => {
          ventas.data.forEach((venta: any) => {
            const fecha_pedido = new Date(venta.pedido_fecha);
            fecha_pedido.setMinutes(fecha_pedido.getMinutes() + fecha_pedido.getTimezoneOffset());
            const obj_ventas = {
              pedido_id: venta.pedido_id + '',
              pedido_fecha: fecha_pedido,
              horeca_nombre: venta.horeca_nombre,
              horeca_tipo_usuario: venta.horeca_tipo_usuario,
              horeca_nit: venta.horeca_nit,
              horeca_tipo_negocio: venta.horeca_tipo_negocio,
              punto_pais: venta.punto_pais,
              punto_departamento: venta.punto_departamento,
              punto_ciudad: venta.punto_ciudad,
              punto_nombre: venta.punto_nombre,
              punto_sillas: venta.punto_sillas,
              punto_domicilios: venta.punto_domicilios == true ? 'Si' : 'No',
              producto_nombre: venta.producto_nombre,
              producto_categoria: venta.producto_categoria,
              producto_linea: venta.producto_linea,
              producto_marca: venta.producto_marca,
              producto_organizacion: venta.producto_organizacion,
              producto_codigo_organizacion: venta.producto_codigo_organizacion,
              producto_codigo_distribuidor: venta.producto_codigo_distribuidor,
              producto_unidad_medida: venta.producto_unidad_medida,
              producto_cantidad_medida: venta.producto_cantidad_medida,
              precio_lista: venta.precioActual,
              porcentaje_descuento: venta.porcentaje_descuento,
              precio_descuento: venta.precioDescuento,
              pedido_cajas: venta.pedido_cajas,
              pedido_unidades: venta.pedido_unidades,
              costoProductos: venta.costoProductos,
              pedido_total: venta.pedido_total != undefined ? venta.pedido_total : 0,
              pedido_puntos_acumulados: venta.pedido_puntos_acumulados,
              equipo_comercial: venta.equipo_comercial,
            };
            data_ventas.push(obj_ventas);
          });
          this.dataHistorico = data_ventas;
          this.prepararArchivoPlano('historico', 'historico_pedidos');
          //this.source_ventas = new LocalDataSource(this.data_ventas);
        })
        .catch((err) => {
          //this.mostrarMensajeError();
        });
    }
}


@Component({
  selector: 'app-btn-editar-pedido-dist',
  template: `
    <button class="btn-purple" (click)="openDetallePedido()">Ver</button>
  `,
  styles: [
    `
      .btn-purple {
        width: 100%;
        padding: 0px;
        background-color: transparent;
        border: transparent;
        color: #8e6ff7;
      }
    `,
  ],
})
export class BtnEditarPedidoDist {
  /** Datos del producto a editar */
  @Input() value: any;
  constructor(private router: Router) {}
  /**
   * Toma el id que entró por parámetro a este componente y abre el componente
   * del detalle de pedido de distribuidor usando el id como parámetro de la ruta
   */
  public openDetallePedido() {
    //this.router.navigate([`/pedidos-distribuidor/${this.value?.id_pedido}`]);
  }
}
